import React, {
  useState,
  useReducer,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import {
  Button,
  Link,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { setModalClose } from "../../../actions/index";
import ProcessName from "./ProcessName";
import ProcessDescription from "./ProcessDescription";
import DateAndFrequency from "./DateAndFrequency";
import FormatAndMultiple from "./FormatAndMultiple";
import SenderAndNotifyGroup from "./SenderAndNotifyGroup";
import PathAndAllowDownload from "./PathAndAllowDownload";
import EmployerList from "./EmployerList";
import { dayjsToUTC } from "../../../utils/time";
import dayjs from "dayjs";
import {
  postDataToAPI,
  getDataFromAPI,
  updateDataToAPI,
} from "../../../utils/api";
import { setErrorMessage, setSuccessMessage } from "../../../actions/index";
import fileProcessReducer from "./reducer";
import CopyProvider from "../../CopyProvider/CopyProvider";
import Permissions from "../../Permissions/Permissions";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    textAlign: "center",
    marginBottom: "20px",
  },
  dialogTitleText: {
    ...theme.typography.h4,
  },
  dialogContent: {
    display: "flex",
    justifyContent: "space-between",
    padding: "40px 30px 16px 30px",
    background: "#fff",
    borderTop: "1px solid #eee",
    borderBottom: "1px solid #eee",
  },
  contentLeft: {
    width: "53%",
  },
  contentRight: {
    width: "43%",
  },
  requiredText: {
    ...theme.typography.subtitle2,
    fontSize: 13,
    color: "#2d2d2d",
    marginLeft: "30px",
  },
  requiredIcon: {
    color: theme.palette.error.light,
  },
  dialogActions: {
    justifyContent: "space-between",
    marginTop: "20px",
    padding: 0,
  },
  actionButton: {
    marginRight: "2rem",
  },
}));

const initialState = {
  name: "",
  frenchName: "",
  description: "",
  frenchDescription: "",
  effectiveDate: dayjsToUTC(),
  expiryDate: "",
  columnCount: 0,
  frequency: "weekly",
  allowedFormats: ["xls"],
  allowMultipleFiles: false,
  allowOtipSender: false,
  allowEmployerSender: false,
  otipNotificationGroup: "",
  fileDirectory: "",
  allowDownload: false,
  employerList: [],
};

function FileProcessModal(props) {
  const classes = useStyles();
  const { reason, data, callback } = props;
  const reduxDispatch = useDispatch();
  const [fileProcessState, dispatch] = useReducer(
    fileProcessReducer,
    initialState
  );
  const {
    name,
    frenchName,
    description,
    frenchDescription,
    effectiveDate,
    expiryDate,
    frequency,
    allowedFormats,
    allowMultipleFiles,
    allowOtipSender,
    allowEmployerSender,
    otipNotificationGroup,
    fileDirectory,
    allowDownload,
    employerList,
  } = fileProcessState;
  const [notificationGroups, setNotificationGroups] = useState([]);

  const handleCancel = useCallback(() => {
    reduxDispatch(setModalClose());
  }, [reduxDispatch]);

  const handleCreate = async () => {
    const result = await postDataToAPI("/fileprocess", fileProcessState);
    if (result instanceof Error) {
      if (result.response && result.response.data) {
        reduxDispatch(setErrorMessage(result.response.data.code));
      } else {
        reduxDispatch(setErrorMessage("OE000"));
      }
    } else {
      reduxDispatch(setSuccessMessage("OE2001", [name]));
      callback();
      reduxDispatch(setModalClose());
    }
  };

  const handleUpdate = async () => {
    const result = await updateDataToAPI(
      `/fileprocess/${data.id}`,
      JSON.stringify({
        id: data.id,
        ...fileProcessState,
      })
    );
    if (result instanceof Error) {
      if (result.response && result.response.data) {
        reduxDispatch(setErrorMessage(result.response.data.code));
      } else {
        reduxDispatch(setErrorMessage("OE000"));
      }
    } else {
      reduxDispatch(setSuccessMessage("OE2002", [name]));
      callback();
      reduxDispatch(setModalClose());
    }
  };

  const dateError = useMemo(() => {
    if (
      effectiveDate === "" ||
      effectiveDate === "Invalid Date" ||
      expiryDate === "Invalid Date" ||
      dayjs(expiryDate).endOf("day").isBefore(dayjs(effectiveDate).endOf("day"))
    ) {
      return true;
    } else {
      return false;
    }
  }, [effectiveDate, expiryDate]);

  useEffect(() => {
    if (allowOtipSender === true && allowEmployerSender === false) {
      dispatch({
        type: "SET_NOTIFY_GROUP",
        payload: "",
      });
      dispatch({
        type: "SET_FILE_PATH",
        payload: "",
      });
    }
  }, [allowEmployerSender, allowOtipSender]);

  useEffect(() => {
    const getNotificationGroups = async () => {
      const result = await getDataFromAPI(
        "/FileProcess/listnotificationgroups"
      );
      if (result instanceof Error) {
        if (result.response && result.response.data) {
          reduxDispatch(setErrorMessage(result.response.data.code));
        } else {
          reduxDispatch(setErrorMessage("OE000"));
        }
      } else {
        setNotificationGroups(result);
      }
    };
    getNotificationGroups();
  }, [reduxDispatch]);

  useEffect(() => {
    if (reason === "update") {
      const {
        name,
        frenchName,
        description,
        frenchDescription,
        effectiveDate,
        expiryDate,
        allowMultipleFiles,
        fileDirectory,
        allowDownload,
        otipNotificationGroup,
        frequency,
        allowedFormats,
        allowOtipSender,
        allowEmployerSender,
        employerList,
      } = data;
      dispatch({
        type: "SET_NAME",
        payload: name,
      });
      dispatch({
        type: "SET_FRENCH_NAME",
        payload: frenchName,
      });
      dispatch({
        type: "SET_DESCRIPTION",
        payload: description,
      });
      dispatch({
        type: "SET_FRENCH_DESCRIPTION",
        payload: frenchDescription,
      });
      dispatch({
        type: "SET_EFFECTIVE_DATE",
        payload: effectiveDate,
      });
      dispatch({
        type: "SET_EXPIRY_DATE",
        payload: expiryDate,
      });
      dispatch({
        type: "SET_FREQUENCY",
        payload: frequency.toLowerCase(),
      });
      dispatch({
        type: "SET_ALLOWED_FORMATS",
        payload: allowedFormats.map((format) => format.toLowerCase()),
      });
      dispatch({
        type: "SET_ALLOWED_MULTIPLE_FILES",
        payload: allowMultipleFiles,
      });
      dispatch({
        type: "SET_ALLOW_OTIP_SENDER",
        payload: allowOtipSender,
      });
      dispatch({
        type: "SET_ALLOW_EMPLOYER_SENDER",
        payload: allowEmployerSender,
      });
      dispatch({
        type: "SET_NOTIFY_GROUP",
        payload: otipNotificationGroup ? otipNotificationGroup : "",
      });
      dispatch({
        type: "SET_FILE_PATH",
        payload: fileDirectory,
      });
      dispatch({
        type: "SET_ALLOW_DOWNLOAD",
        payload: allowDownload,
      });
      dispatch({
        type: "SET_EMPLOYER_LIST",
        payload: employerList,
      });
    }
  }, [data, reason]);

  const proceedButtonDisabled =
    name === "" ||
    frenchName === "" ||
    effectiveDate === "" ||
    allowedFormats.length === 0 ||
    (allowEmployerSender === false && allowOtipSender === false) ||
    employerList.length === 0 ||
    dateError;

  return (
    <Dialog
      disableEnforceFocus
      disableBackdropClick
      disableEscapeKeyDown
      open={true}
      maxWidth="lg"
      fullWidth
      aria-labelledby="alert-dialog-title"
    >
      <div className={classes.dialogTitle}>
        <h3 id="alert-dialog-title" className={classes.dialogTitleText}>
          {reason.toLowerCase() === "create"
            ? "Create File Process"
            : "Update File Process"}
        </h3>
      </div>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.contentLeft}>
          <ProcessName
            dispatch={dispatch}
            name={name}
            frenchName={frenchName}
          />
          <ProcessDescription
            dispatch={dispatch}
            description={description}
            frenchDescription={frenchDescription}
          />
          <DateAndFrequency
            dispatch={dispatch}
            effectiveDate={effectiveDate}
            expiryDate={expiryDate}
            frequency={frequency}
          />
          <FormatAndMultiple
            dispatch={dispatch}
            allowedFormats={allowedFormats}
            allowMultipleFiles={allowMultipleFiles}
          />
          <SenderAndNotifyGroup
            dispatch={dispatch}
            allowOtipSender={allowOtipSender}
            allowEmployerSender={allowEmployerSender}
            otipNotificationGroup={otipNotificationGroup}
            notificationGroups={notificationGroups}
          />
          <PathAndAllowDownload
            dispatch={dispatch}
            fileDirectory={fileDirectory}
            allowDownload={allowDownload}
            allowOtipSender={allowOtipSender}
            allowEmployerSender={allowEmployerSender}
          />
        </div>
        <div className={classes.contentRight}>
          <EmployerList
            dispatch={dispatch}
            selectedEmployerList={employerList}
            reason={reason}
          />
        </div>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <div className={classes.requiredText}>
          <span className={classes.requiredIcon}>*</span> Required
        </div>
        <div>
          <Link
            color="primary"
            variant="h6"
            component="button"
            className={classes.actionButton}
            onClick={handleCancel}
          >
            <CopyProvider
              page={"change password"}
              type={"label"}
              id={"cancel"}
            />
          </Link>
          {reason.toLowerCase() === "create"
            ? (
                <Permissions type={"FileProcess"} actions={["create"]}>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.actionButton}
                    disabled={proceedButtonDisabled}
                    onClick={handleCreate}
                  >
                    Create
                  </Button>
                </Permissions>
              ) || (
                <Permissions type={"EmployerProcess"} actions={["create"]}>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.actionButton}
                    disabled={proceedButtonDisabled}
                    onClick={handleCreate}
                  >
                    Create
                  </Button>
                </Permissions>
              )
            : (
                <Permissions type={"FileProcess"} actions={["update"]}>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.actionButton}
                    disabled={proceedButtonDisabled}
                    onClick={handleUpdate}
                  >
                    Update
                  </Button>
                </Permissions>
              ) || (
                <Permissions type={"EmployerProcess"} actions={["update"]}>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.actionButton}
                    disabled={proceedButtonDisabled}
                    onClick={handleUpdate}
                  >
                    Update
                  </Button>
                </Permissions>
              )}
        </div>
      </DialogActions>
    </Dialog>
  );
}

FileProcessModal.propTypes = {
  reason: PropTypes.string.isRequired,
};

FileProcessModal.defaultProps = {
  reason: "create",
};

export default FileProcessModal;
