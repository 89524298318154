import React, { useCallback, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormGroup,
  Typography,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from "@material-ui/core";
import PropTypes from "prop-types";
import FormatCheckboxes from "./FormatCheckboxes";

const useStyles = makeStyles((theme) => ({
  row: {
    marginBottom: theme.spacing(2),
  },
  formGroup: {
    justifyContent: "space-between",
  },
  required: {
    color: theme.palette.error.light,
  },
  label: {
    ...theme.typography.h6,
  },
  formControlLabel: {
    marginRight: 0,
  },
}));

const FormatAndMultiple = React.memo(function FormatAndMultiple(props) {
  const classes = useStyles();
  const { dispatch, allowedFormats, allowMultipleFiles } = props;

  const handleFormatChange = useCallback(
    (isChecked, format) => {
      let newAllowedFormats;
      if (isChecked) {
        newAllowedFormats = [...allowedFormats, format];
      } else {
        newAllowedFormats = allowedFormats.filter(
          (formatName) => formatName !== format
        );
      }

      dispatch({
        type: "SET_ALLOWED_FORMATS",
        payload: newAllowedFormats,
      });
    },
    [dispatch, allowedFormats]
  );

  const error = useMemo(() => allowedFormats.length === 0, [allowedFormats]);

  return (
    <div className={classes.row}>
      <Typography className={classes.label}>
        Format <span className={classes.required}>*</span>
      </Typography>
      <FormGroup row className={classes.formGroup}>
        <FormatCheckboxes
          allowedFormats={allowedFormats}
          handleFormatChange={handleFormatChange}
        />
        <div>
          {" "}
          <FormControlLabel
            className={classes.formControlLabel}
            control={
              <Checkbox
                checked={allowMultipleFiles}
                onChange={(e) => {
                  dispatch({
                    type: "SET_ALLOWED_MULTIPLE_FILES",
                    payload: e.target.checked,
                  });
                }}
                name="allow-multiple-files"
                color="primary"
              />
            }
            label="Multiple File Selection"
          />
        </div>
      </FormGroup>
      {error && (
        <FormHelperText error>This is a required field.</FormHelperText>
      )}
    </div>
  );
});

FormatAndMultiple.propTypes = {
  dispatch: PropTypes.func.isRequired,
  allowedFormats: PropTypes.array.isRequired,
  allowMultipleFiles: PropTypes.bool.isRequired,
};

export default FormatAndMultiple;
