import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { setEmployerInfo } from "../../actions/index";
import { Typography } from "@material-ui/core";

function EmployerLabel() {
  const employerList = useSelector((state) => state.employerList, _.isEqual);
  const employer = useSelector((state) => state.employerInfo);
  const dispatch = useDispatch();

  useEffect(() => {
    if (employerList.length > 0) {
      const employerInfo = { ...employerList[0] };
      dispatch(setEmployerInfo(employerInfo));
    }
  }, [employerList, dispatch]);

  return <Typography variant="body1">{`${employer.id} -- ${employer.name}`}</Typography>;
}

export default EmployerLabel;
