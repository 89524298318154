import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import languageSource from "./translation.json";

/*
  NOTE: the convert_csv_to_json.js file is meant to be used with a specific CSV
  format exported from a specific XLXS file
  changes to that file that file will affect the out put and thus some of the
  JSON ref names. A this point there should be no reason to need this
  tool again unless a huge change to the copy of the portal is called for.

  If you do have to use this tool again note that the file encoding for most of
  the CSV-to-JSON converters do not support utf-8 properly and replace the
  French (expended Latin characters) with the dreaded <?> character so you will
  have to do French manually. This is solvable but not in the time available.

  -----------------------------------------------------

  CopyProvider takes three main props: page, type and id. Each of these props is
  a node on the JSON object creating a path to
  the copy to be presented. The Language portion is supplied by the global store.

  Two additional props can be passed down, inserts and moveWord.

  inserts allows for passing an array of values to be replaced in the retrieved copy.
  The copy contains the character sequence &n which is replaced in sequence by
  the inserts array values via regex.

  moveWord signifies that a word in the provided copy should be extracted and placed
  in a new position. Currently this position is first props.Children element and
  word should be the first word (0). This can be made more sophisticated in the future
  if required, the parts are there and the signature should not have to change much if at all.

  if you provide a child element the component will place the specified copy in the
  provided element.

  if 2 children are given AND moveWord is specified the compoent will move the
  given word to the first element and the rest of copy to the second element

  if no children are given the component will output just the specified copy

  Regardless of any of the above conditions any inserts provided will by applied.

*/

/**
 * 
 * @param {Object} props
 * @param {?(React.ReactNode | React.ReactNode[])} props.children A list of React DOM elements
 * @param {String} props.page The page
 * @param {String} props.type The type
 * @param {String} props.id The id
 param {?Array} props.inserts A list of values to be inserted to the copy text parameters
 * @param {?Number} props.moveWord ???
 * @returns 
 */
export default function CopyProvider(props) {
  const languagePreference = useSelector(
    (state) => state.employerInfo.langPref
  );
  let langPref;
  const userGroup = useSelector((state) => state.userInfo.group);

  if (userGroup.toLowerCase() === "otip") {
    langPref = "en";
  } else {
    langPref = languagePreference;
  }

  const page = props.page.toLowerCase();
  const type = props.type.toLowerCase();
  const id = props.id.toLowerCase();
  if (languageSource.page == null || 
    languageSource.page[page] == null ||
    languageSource.page[page][type] == null ||
    languageSource.page[page][type][id] == null ||
    languageSource.page[page][type][id][langPref] == null)
  {
    throw new Error(`Missing copy: ${languageSource.page == null ?
      "page in languageSource" : languageSource.page[page] == null ?
      `'${page}' in languageSource.page` : languageSource.page[page][type] == null ?
      `'${type}' in languageSource.page[${page}].type` : languageSource.page[page][type][id] == null ?
      `'${id}' in languageSource.page[${page}].type[${type}].id` : languageSource.page[page][type][id][langPref] == null ?
      `'${langPref}' in languageSource.page[${page}].type[${type}].id[${id}].langPref` : "???"
    }`);
  }
  let copy = languageSource.page[page][type][id][langPref];
  const regex = /&n/;

  function generateOutPut() {
    let output;
    // props.inserts:  an array of values that get inserted into the indicated copy via standard regex
    if (props.inserts && props.inserts.length > 0) {
      props.inserts.forEach((insert) => {
        copy = copy.replace(regex, insert);
      });
    }

    if (React.Children.count(props.children) !== 0) {
      if (props.moveWord === undefined) {
        output = React.cloneElement(
          React.Children.toArray(props.children)[0],
          null,
          copy
        );
      } else {
        //allows you extract a word and put it in the first child element
        //a little more work can make both word and element arbitrary
        const copyFragments = copy.split(" ");
        const wordToMove = copyFragments[props.moveWord];
        output = [
          React.cloneElement(
            React.Children.toArray(props.children)[0],
            null,
            wordToMove
          ),
          <span> </span>,
          React.cloneElement(
            React.Children.toArray(props.children)[1],
            null,
            copyFragments.slice(props.moveWord + 1).join(" ")
          ),
        ];
      }
    } else {
      output = <>{copy}</>;
    }

    return output;
  }
  return generateOutPut();
}

CopyProvider.propTypes = {
  children: PropTypes.node,
  page: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  inserts: PropTypes.array,
  moveWord: PropTypes.number
};
