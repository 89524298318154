import { SET_EMPLOYER_INFO } from "../actions/employerInfoActions";

const employerInfoReducer = (
  state = { id: "", name: "", langPref: "en", userGroup: "" },
  action
) => {
  switch (action.type) {
    case SET_EMPLOYER_INFO: {
      return { ...action.payload };
    }
    default: {
      return state;
    }
  }
};

export default employerInfoReducer;
