import { SET_MODAL_OPEN, SET_MODAL_CLOSE } from "../actions/index";

const modalReducer = (state = null, action) => {
  switch (action.type) {
    case SET_MODAL_OPEN: {
      return {
        modalType: action.payload.modalType,
        modalProps: action.payload.modalProps
      };
    }

    case SET_MODAL_CLOSE: {
      return null;
    }

    default: {
      return state;
    }
  }
};

export default modalReducer;
