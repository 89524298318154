import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import { SnackbarProvider } from "notistack";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DayjsUtils from "@date-io/dayjs";
import theme from "./theme/index";
import Routes from "./Routes";
import ModalManager from "./components/ModalManager/ModalManager";
import userAgentApplication from "./utils/auth";
import { useDispatch, useSelector } from "react-redux";
import "./assets/scss/index.scss";
import {
  setAuthenticationStatus,
  setUserInfo,
  fetchEmployerList,
  fetchUnreadFilesCount,
  setModalOpen,
} from "./actions/index";
import StackMessage from "./components/Message/StackMessage";
import HeartbeatCheck from "./components/HeartbeatCheck/HeartbeatCheck";

function App() {
  const dispatch = useDispatch();
  const userGroup = useSelector((state) => state.userInfo.group);
  const employerId = useSelector((state) => state.employerInfo.id);

  useEffect(() => {
    // Init Status
    const account = userAgentApplication.getAccount();
    if (account) {
      // masterGroup depends on GetUserGroup determining that the user group configuration is valid.
      // Thus, at this point we can check if the groups contains OTIP or Employer.
      var masterGroup = "Employer";
      if (account.idToken.groups.find(el => el.toUpperCase() === "OTIP")) {
        masterGroup = "OTIP";
      }
      dispatch(setAuthenticationStatus(true));
      dispatch(
        setUserInfo({
          name: account.idToken.name,
          id: account.idToken.sub,
          group: masterGroup,
        })
      );
      dispatch(fetchEmployerList());
    } else {
      dispatch(setAuthenticationStatus(false));
    }
  }, [dispatch]);

  useEffect(() => {
    // Check if user is from OTIP and if it just logged in
    if (userGroup && userGroup.toLowerCase() === "otip") {
      if (!localStorage.getItem("school_board_number")) {
        setTimeout(() => {
          dispatch(setModalOpen("SelectEmployerModal"));
        }, 200);
      }
    }
  }, [userGroup, dispatch]);

  useEffect(() => {
    if (employerId) {
      dispatch(fetchUnreadFilesCount(employerId));
    }
  }, [employerId, dispatch]);

  return <>
    <HeartbeatCheck heartbeatThresholdMS={5 * 60 * 1000} />
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={50}>
        <MuiPickersUtilsProvider utils={DayjsUtils}>
          <Router>
            <Routes />
          </Router>
          <ModalManager />
          <StackMessage />
        </MuiPickersUtilsProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </>;
}

export default App;
