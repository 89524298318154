import { combineReducers } from "redux";
import authReducer from "./authReducer";
import userInfoReducer from "./userInfoReducer";
import modalReducer from "./modalReducer";
import employerListReducer from "./employerListReducer";
import employerInfoReducer from "./employerInfoReducer";
import unreadFilesCountReducer from "./unreadFilesCountReducer";
import messageReducer from "./messageReducer";
import permissionsReducer from "./permissionsReducer";

const rootReducer = combineReducers({
  authenticated: authReducer,
  userInfo: userInfoReducer,
  modal: modalReducer,
  employerList: employerListReducer,
  employerInfo: employerInfoReducer,
  unreadFilesCount: unreadFilesCountReducer,
  message: messageReducer,
  permissions: permissionsReducer,
});

export default rootReducer;
