import { SET_PERMISSIONS } from "../actions/index";

const permissionsReducer = (state = null, action) => {
  switch (action.type) {
    case SET_PERMISSIONS: {
      return new Map(action.payload);
    }

    default: {
      return state;
    }
  }
};

export default permissionsReducer;

