let redirectUri = window.location.origin;
let webApi =
  process.env.REACT_APP_WEB_API.substring(0, 4) === "http"
    ? process.env.REACT_APP_WEB_API.replace(/\/$/g, "")
    : `${redirectUri}/${process.env.REACT_APP_WEB_API.replace(
        /^\/*|\/*$/g,
        ""
      )}`;
const b2cPolicies = {
  names: {
    signIn: "B2C_1A_SignInWithForgotPassword",
    forgotPassword: "B2C_1A_PasswordReset",
  },
  authorities: {
    signIn: `https://${process.env.REACT_APP_AZURE_ADB2C_INSTANCE}/tfp/${process.env.REACT_APP_AZURE_TENANT}/B2C_1A_SignInWithForgotPassword`,
    forgotPassword: `https://${process.env.REACT_APP_AZURE_ADB2C_INSTANCE}/${process.env.REACT_APP_AZURE_TENANT}/oauth2/v2.0/authorize?p=B2C_1A_PasswordReset&client_id=${process.env.REACT_APP_AZURE_UI_CLIENT_ID}&nonce=defaultNonce&redirect_uri=${redirectUri}&scope=openid&response_type=code&response_mode=query`
  },
};

module.exports = {
  authConfig: {
    auth: {
      clientId: process.env.REACT_APP_AZURE_UI_CLIENT_ID, // UI Application ID
      authority: b2cPolicies.authorities.signIn, //A URL indicating a directory that MSAL can request tokens from
      validateAuthority: false,
      redirectUri: redirectUri,
      postLogoutRedirectUri: redirectUri,
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: false,
    },
  },
  appConfig: {
    b2cScopes: [
      `https://${process.env.REACT_APP_AZURE_TENANT}/otipemployerportalapi/user_impersonation`,
    ],
    b2cPolicies,
    frenchOriginUrl: process.env.REACT_APP_FRENCH_ORIGIN_URL,
    englishOriginUrl: process.env.REACT_APP_ENGLISH_ORIGIN_URL,
  },
  webApi: webApi,
  maximumFileUpload: process.env.REACT_APP_MAXIMUM_FILE_UPLOAD,
  maximumFileBulkUpload: process.env.REACT_APP_MAXIMUM_BULK_FILE_UPLOAD,
  maximumFileDownload: process.env.REACT_APP_MAXIMUM_FILE_DOWNLOAD,
  maximumFileSize: process.env.REACT_APP_MAXIMUM_FILE_SIZE,
};
