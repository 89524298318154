export default {
  select: {
    "&:focus": {
      backgroundColor: "rgba(0,0,0,0)",
    },
  },
  outlined: {
    paddingTop: "12px",
    paddingBottom: "12px",
  },
};
