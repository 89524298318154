import {
  SET_ERROR_MESSAGE,
  SET_WARNING_MESSAGE,
  SET_SUCCESS_MESSAGE,
  SET_CLEAR_MESSAGE,
} from "../actions/index";

const initialState = {
  severity: "success",
  message: "",
};

const messageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ERROR_MESSAGE: {
      return { ...action.payload };
    }
    case SET_WARNING_MESSAGE: {
      return { ...action.payload };
    }
    case SET_SUCCESS_MESSAGE: {
      return { ...action.payload };
    }
    case SET_CLEAR_MESSAGE: {
      return { severity: "success", message: "" };
    }
    default: {
      return state;
    }
  }
};

export default messageReducer;
