import React from "react";
import { useSelector } from "react-redux";
import SelectEmployerModal from "./SelectEmployerModal";
import DownloadFilesModal from "./DownloadFilesModal";
import FileProcessModal from "./FileProcessModal/FileProcessModal";

const modalLookup = {
  SelectEmployerModal,
  DownloadFilesModal,
  FileProcessModal,
};

function ModalManager() {
  const currentModal = useSelector((state) => state.modal);
  let renderedModal;

  if (currentModal) {
    const { modalType, modalProps } = currentModal;
    const ModalComponent = modalLookup[modalType];
    renderedModal = <ModalComponent {...modalProps} />;
  }
  return <div id="modal">{renderedModal}</div>;
}

export default ModalManager;
