import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { makeStyles, withStyles } from "@material-ui/styles";
import { Checkbox, ListItem, colors, Tooltip } from "@material-ui/core";
import { convertISOFormat } from "../../utils/time";
import Permissions from "../../components/Permissions/Permissions";
import { useDispatch, useSelector } from "react-redux";
import CopyProvider from "../../components/CopyProvider/CopyProvider";
import { patchDataToAPI } from "../../utils/api";
import { setErrorMessage, setSuccessMessage } from "../../actions/index";
import { Link } from "../../../node_modules/@material-ui/core/index";

export const SmallTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: "12px",
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    "&:hover": {
      backgroundColor: colors.grey[50],
    },
  },
  selected: {
    backgroundColor: colors.grey[50],
  },
  bold: {
    fontWeight: "600 !important",
  },
  overflowText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  details: {
    minWidth: 1,
    display: "flex",
    flexGrow: 1,
    justifyContent: "space-between",
    minHeight: "42px",
  },
  iconNameContainer: {
    display: "flex",
    alignItems: "center",
  },
  iconFilled: {
    fontWeight: 900,
  },
  fileIcon: {
    fontSize: "20px",
    color: theme.palette.primary.main,
    margin: "0 18px 0 20px",
  },
  fileIconRed: {
    color: theme.palette.error.main,
  },
  fileNameContainer: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "400px",
  },
  fileProcessName: {
    ...theme.typography.body1,
    letterSpacing: "0.1px",
  },
  fileName: {
    ...theme.typography.subtitle2,
    fontSize: 13,
    letterSpacing: "0.3px",
  },
  senderDateContainer: {
    display: "flex",
    alignItems: "center",
    width: "310px",
    justifyContent: "right",
    flex: 1
  },
  sender: {
    ...theme.typography.body1,
    letterSpacing: "0.1px",
    maxWidth: "200px",
    padding: "20px"
  },
  date: {
    ...theme.typography.body1,
    letterSpacing: "0.2px",
    maxWidth: "200px",
    padding: "20px"
  },
  actionContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px"
  },
  tooltip: {
    backgroundColor: theme.palette.primary,
  },
  deleteFileLink: {
    cursor: "pointer"
  },
  undoDeleteFileLink: {
    cursor: "pointer"
  }
}));

function FileItem(props) {
  const classes = useStyles();
  const { fileObj, checked, dispatch, fetchHandler } = props;
  const {
    fileName,
    processName,
    frenchProcessName,
    uploadedByName,
    uploaded,
    isOpened,
    isRestricted,
    hasPermission,
    status
  } = fileObj;
  const langPref = useSelector((state) => state.employerInfo.langPref);
  const reduxDispatch = useDispatch();
  
  const selectRow = e => {
    if (!isRestricted || hasPermission) {
      dispatch({ type: "SET_TOGGLE_SELECT_ONE", payload: fileObj });
    }
  };

  const isActive = status === "active";
  const isDeleted = status === "deleted";
  const isBold = !isOpened;

  async function deleteFile(event)
  {
    event.preventDefault();
    const result = await patchDataToAPI(`/files/${fileObj.id}`, [
      {
        "op": "replace",
        "path": "/status",
        "value": "deleted"
      }
    ]);
    if (result instanceof Error) {
      if (result.response && result.response.data) {
        dispatch(setErrorMessage(result.response.data.code));
      } else {
        dispatch(setErrorMessage("OE000"));
      }
    }
    else if (result)
    {
      reduxDispatch(setSuccessMessage("OE2004"));
      fetchHandler();
    }
  }

  async function undoDeleteFile(event)
  {
    event.preventDefault();
    const result = await patchDataToAPI(`/files/${fileObj.id}`, [
      {
        "op": "replace",
        "path": "/status",
        "value": "active"
      }
    ]);
    if (result instanceof Error) {
      if (result.response && result.response.data) {
        dispatch(setErrorMessage(result.response.data.code));
      } else {
        dispatch(setErrorMessage("OE000"));
      }
    }
    else if (result)
    {
      reduxDispatch(setSuccessMessage("OE2005"));
      fetchHandler();
    }
  }

  function actionContainer()
  {
    return (
      <div className={classes.actionContainer}>
        {
          isActive && <Permissions type={"Files"} actions={["delete"]}>
            <Tooltip
              title={
                <CopyProvider
                  page={"files"}
                  type={"label"}
                  id={"delete"}
                />
              }
              placement="top"
              arrow
              className={classes.tooltip}
            >
              <Link
                color="primary"
                variant="inherit"
                className={classes.deleteFileLink}
                onClick={deleteFile}>
                <i className={classnames(`fas fa-trash ${classes.fileIcon}`)}></i>
              </Link>
            </Tooltip>
          </Permissions>
        }
        {
          isDeleted && <Permissions type={"Files"} actions={["delete"]}>
            <Tooltip
              title={
                <CopyProvider
                  page={"files"}
                  type={"label"}
                  id={"undodelete"}
                />
              }
              placement="top"
              arrow
              className={classes.tooltip}
            >
              <Link
                color="primary"
                variant="inherit"
                className={classes.undoDeleteFileLink}
                arial-label={"test"}
                onClick={undoDeleteFile}>
                  <i className={classnames(`fas fa-undo ${classes.fileIcon}`)}></i>
              </Link>
            </Tooltip>
          </Permissions>
        }
      </div>
    );
  }

  return (
    <ListItem
      className={classes.root}
      disableGutters
      divider
      selected={checked}
    >
      <Permissions type={"FileDownload"} actions={["read"]}>
        <Permissions type={"FileDownloadRestricted"} actions={["read"]}>
          <Checkbox checked={checked} color="primary" disabled={isRestricted && !hasPermission} onClick={selectRow} />
          <Checkbox checked={checked} color="primary" disabled={false} onClick={selectRow} />
        </Permissions>
      </Permissions>
      <Permissions type={"FileDownloadRestricted"} actions={["read"]}>
        <div className={classes.details}>
          <div className={classes.iconNameContainer}>
            <i
              className={classnames("fal", {
                "fa-file-alt": !isRestricted,
                "fa-shield-alt": isRestricted,
                [classes.fileIcon]: true,
                [classes.fileIconRed]: isRestricted,
                [classes.iconFilled]: !isOpened && !isRestricted,
              })}
            ></i>
            <div className={classes.fileNameContainer}>
              <SmallTooltip
                title={langPref === "fr" ? frenchProcessName : processName}
                placement="right"
              >
                <div
                  className={classnames({
                    [classes.fileProcessName]: true,
                    [classes.overflowText]: true,
                    [classes.bold]: isBold,
                  })}
                >
                  {langPref === "fr" ? frenchProcessName : processName}
                </div>
              </SmallTooltip>
              <SmallTooltip title={fileName} placement="right">
                <div
                  className={classnames({
                    [classes.fileName]: true,
                    [classes.overflowText]: true,
                    [classes.bold]: isBold,
                  })}
                >
                  {fileName}
                </div>
              </SmallTooltip>
            </div>
          </div>
          <div className={classes.senderDateContainer}>
            <SmallTooltip title={uploadedByName} placement="right">
              <div
                className={classnames({
                  [classes.sender]: true,
                  [classes.overflowText]: true,
                  [classes.bold]: isBold,
                })}
              >
                {uploadedByName}
              </div>
            </SmallTooltip>
            <div
              className={classnames({
                [classes.date]: true,
                [classes.overflowText]: true,
                [classes.bold]: isBold,
              })}
            >
              {convertISOFormat(uploaded)}
            </div>
          </div>
          {actionContainer()}
        </div>
        <div className={classes.details}>
          <div className={classes.iconNameContainer}>
            <i
              className={classnames("fal", {
                "fa-file-alt": !isRestricted,
                "fa-shield-alt": isRestricted,
                [classes.fileIcon]: true,
                [classes.fileIconRed]: isRestricted,
                [classes.iconFilled]: !isOpened && !isRestricted,
              })}
            ></i>
            <div className={classes.fileNameContainer}>
              <SmallTooltip
                title={langPref === "fr" ? frenchProcessName : processName}
                placement="right"
              >
                <div
                  className={classnames({
                    [classes.fileProcessName]: true,
                    [classes.overflowText]: true,
                    [classes.bold]: isBold
                  })}
                >
                  {langPref === "fr" ? frenchProcessName : processName}
                </div>
              </SmallTooltip>
              <SmallTooltip title={fileName} placement="right">
                <div
                  className={classnames({
                    [classes.fileName]: true,
                    [classes.overflowText]: true,
                    [classes.bold]: isBold
                  })}
                >
                  {fileName}
                </div>
              </SmallTooltip>
            </div>
          </div>
          <div className={classes.senderDateContainer}>
            <SmallTooltip title={uploadedByName} placement="right">
              <div
                className={classnames({
                  [classes.sender]: true,
                  [classes.overflowText]: true,
                  [classes.bold]: isBold,
                })}
              >
                {uploadedByName}
              </div>
            </SmallTooltip>
            <div
              className={classnames({
                [classes.date]: true,
                [classes.overflowText]: true,
                [classes.bold]: isBold,
              })}
            >
              {convertISOFormat(uploaded)}
            </div>
          </div>
          {actionContainer()}
        </div>
      </Permissions>
    </ListItem>
  );
}

FileItem.propTypes = {
  dispatch: PropTypes.func.isRequired,
  fileObj: PropTypes.object.isRequired,
  checked: PropTypes.bool.isRequired,
  fetchHandler: PropTypes.func.isRequired
};

export default FileItem;
