import React from "react";
import { Typography, FormControl, Select, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { KeyboardDatePicker } from "@material-ui/pickers";
import PropTypes from "prop-types";
import { dayjsToUTC } from "../../../utils/time";
import CopyProvider from "../../CopyProvider/CopyProvider";
import Permissions from "../../Permissions/Permissions";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },
  rowItem: {
    width: "30%",
  },
  required: {
    color: theme.palette.error.light,
  },
  label: {
    ...theme.typography.h6,
    marginBottom: theme.spacing(1),
  },
  select: {
    width: "100%",
  },
}));

const DateAndFrequency = React.memo(function DateAndFrequency(props) {
  const classes = useStyles();
  const { dispatch, effectiveDate, expiryDate, frequency } = props;

  return (
    <div className={classes.row}>
      <div className={classes.rowItem}>
        <Typography className={classes.label}>
          Effective Date <span className={classes.required}>*</span>
        </Typography>
        <KeyboardDatePicker
          autoOk
          variant="inline"
          inputVariant="outlined"
          format="YYYY/MMM/DD"
          InputAdornmentProps={{
            position: "start",
          }}
          value={effectiveDate === "" ? null : effectiveDate}
          onChange={(date) => {
            dispatch({
              type: "SET_EFFECTIVE_DATE",
              payload: dayjsToUTC(date),
            });
          }}
          style={{ maxWidth: "100%" }}
        />
      </div>
      <div className={classes.rowItem}>
        <Permissions type={"FileProcess"} actions={["enable"]}>
          <>
            <Typography className={classes.label}>Expiry Date</Typography>
            <KeyboardDatePicker
              autoOk
              variant="inline"
              inputVariant="outlined"
              format="YYYY/MMM/DD"
              InputAdornmentProps={{ position: "start" }}
              minDate={effectiveDate}
              minDateMessage="Expiry date must be after Effective date."
              value={expiryDate === "" ? null : expiryDate}
              onChange={(date) => {
                dispatch({
                  type: "SET_EXPIRY_DATE",
                  payload: date ? dayjsToUTC(date) : "",
                });
              }}
              style={{ maxWidth: "100%" }}
            />
          </>
        </Permissions>
      </div>
      <div className={classes.rowItem}>
        <Typography className={classes.label}>
          Frequency <span className={classes.required}>*</span>
        </Typography>
        <FormControl variant="outlined" className={classes.select}>
          <Select
            value={frequency}
            onChange={(e) => {
              dispatch({
                type: "SET_FREQUENCY",
                payload: e.target.value,
              });
            }}
            inputProps={{
              name: "frequency",
              "aria-label": "frequency",
            }}
          >
            <MenuItem value="weekly">
              <CopyProvider page={"upload file"} type={"label"} id={"weekly"} />
            </MenuItem>
            <MenuItem value="bi-weekly">
              <CopyProvider
                page={"upload file"}
                type={"label"}
                id={"biweekly"}
              />
            </MenuItem>
            <MenuItem value="monthly">
              <CopyProvider
                page={"upload file"}
                type={"label"}
                id={"Monthly"}
              />
            </MenuItem>
            <MenuItem value="yearly">
              <CopyProvider page={"upload file"} type={"label"} id={"Yearly"} />
            </MenuItem>
            <MenuItem value="adhoc">
              <CopyProvider page={"upload file"} type={"label"} id={"Adhoc"} />
            </MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  );
});

DateAndFrequency.propTypes = {
  dispatch: PropTypes.func.isRequired,
  effectiveDate: PropTypes.string.isRequired,
  expiryDate: PropTypes.string,
  frequency: PropTypes.string.isRequired,
};

export default DateAndFrequency;
