import React, { useState, useCallback } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Drawer,
  IconButton,
  Divider,
  Collapse,
  List,
  ListItem,
  Tooltip,
} from "@material-ui/core";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import UnreadFilesCount from "../../components/UnreadFilesCount/UnreadFilesCount";
import Permissions from "../../components/Permissions/Permissions";
import CopyProvider from "../../components/CopyProvider/CopyProvider";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  bold: {
    color: `${theme.palette.text.primary} !important`,
    fontWeight: "600 !important",
  },
  highlight: {
    borderLeft: "3px solid #d9a867 !important",
    backgroundColor: "#fbf6f0",
  },
  groupHighlight: {
    backgroundColor: "#fbf6f0",
  },
  drawer: {
    width: "270px",
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: "270px",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    "& .logo": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "1.5rem 0",
    },
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: "81px",
    "& .logo": {
      width: "58px",
      margin: "31px auto 30px",
    },
    "& .logo-svg": {
      width: "100%",
    },
    "& .nav-link-icon": {
      paddingLeft: "7px",
    },
    "& .nav-link": {
      opacity: 0,
    },
    "& .subNavLink-group": {
      display: "none",
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItem: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    borderLeft: "3px solid #fff",
  },
  tooltip: {
    backgroundColor: theme.palette.primary,
  },
  navLink: {
    ...theme.typography.h5,
    color: theme.palette.primary.main,
    fontWeight: 600,
    marginLeft: "10px",
  },
  navLinkIcon: {
    fontSize: "22px",
    lineHeight: 1,
    color: theme.palette.primary.main,
  },
  nested: {
    paddingLeft: theme.spacing(6),
    fontSize: "13px",
  },
  subNavLink: {
    ...theme.typography.subtitle1,
  },
  toggleButton: {
    position: "absolute",
    right: "10px",
    bottom: "10px",
    "& i": {
      fontSize: "1rem",
      fontWeight: 600,
      cursor: "pointer",
    },
  },
  unreadCount: {
    position: "absolute",
    top: "20px",
    right: "1rem",
  },
}));

export default function NavBar() {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const pathname = useLocation().pathname;

  const userGroup = useSelector((state) => state.userInfo.group);

  const isOtip = userGroup && userGroup.toLowerCase() === "otip";

  const handleDrawerOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const handleDrawerClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <Drawer
      variant="permanent"
      className={classnames(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: classnames({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      {/* <div
        className={classnames("fal", {
          [classes.toggleButton]: true,
          "fa-chevron-double-left": open,
          "fa-chevron-double-right": !open,
        })}
        onClick={open ? handleDrawerClose : handleDrawerOpen}
      ></div> */}

      <IconButton
        onClick={open ? handleDrawerClose : handleDrawerOpen}
        className={classes.toggleButton}
      >
        {open ? (
          <i className="fal fa-chevron-double-left"></i>
        ) : (
          <i className="fal fa-chevron-double-right"></i>
        )}
      </IconButton>
      <div className={classes.toolbar}>
        <div className="logo">
          <img
            className="logo-svg"
            src="./images/logo-svg.svg"
            alt="logo-icon"
          />
        </div>
      </div>
      <Divider />

      <List className={classes.list}>
        <Link to="/dashboard">
          <Tooltip
            title={
              <CopyProvider
                page={"navigation (left)"}
                type={"label"}
                id={"dashboard"}
              />
            }
            placement="right"
            arrow
            className={classes.tooltip}
          >
            <ListItem
              button
              className={classnames({
                [classes.listItem]: true,
                [classes.highlight]: pathname === "/dashboard",
              })}
            >
              <i
                className={classnames("fal fa-rocket-launch nav-link-icon", {
                  [classes.navLinkIcon]: true,
                })}
                style={{ fontSize: "20px" }}
              ></i>
              <span
                className={classnames("nav-link", {
                  [classes.navLink]: true,
                  [classes.bold]: pathname === "/dashboard",
                })}
              >
                <CopyProvider
                  page={"navigation (left)"}
                  type={"label"}
                  id={"dashboard"}
                />
              </span>
            </ListItem>
          </Tooltip>
        </Link>
        {isOtip && <Permissions type={"files"} actions={["read"]}>
          <List className={classes.list}>
            <Link to="/search">
              <Tooltip
                title={
                  <CopyProvider
                    page={"navigation (left)"}
                    type={"label"}
                    id={"search"}
                  />
                }
                placement="right"
                arrow
                className={classes.tooltip}
              >
                <ListItem
                  button
                  className={classnames({
                    [classes.listItem]: true,
                    [classes.highlight]: pathname === "/search",
                  })}
                >
                  <i
                    className={classnames("fal fa-search nav-link-icon", {
                      [classes.navLinkIcon]: true,
                    })}
                    style={{ fontSize: "20px" }}
                  ></i>
                  <span
                    className={classnames("nav-link", {
                      [classes.navLink]: true,
                      [classes.bold]: pathname === "/search",
                    })}
                  >
                    <CopyProvider
                      page={"navigation (left)"}
                      type={"label"}
                      id={"search"}
                    />
                  </span>
                </ListItem>
              </Tooltip>
            </Link>
          </List>
        </Permissions>}

        <Permissions type={"files"} actions={["read"]}>
          <Link to="/files">
            <Tooltip
              title={
                <CopyProvider
                  page={"navigation (left)"}
                  type={"label"}
                  id={"files"}
                />
              }
              placement="right"
              arrow
            >
              <ListItem
                button
                className={classnames({
                  [classes.listItem]: true,
                  [classes.highlight]:
                    pathname === "/files" ||
                    pathname === "/upload-file" ||
                    pathname === "/bulk-upload" ||
                    pathname === "/file-processes" ||
                    pathname === "/employer-processes",
                })}
              >
                <i
                  className={classnames("fal fa-copy nav-link-icon", {
                    [classes.navLinkIcon]: true,
                  })}
                  style={{ fontSize: "22.86px" }}
                ></i>
                <span
                  className={classnames("nav-link", {
                    [classes.navLink]: true,
                    [classes.bold]:
                      pathname === "/files" ||
                      pathname === "/upload-file" ||
                      pathname === "/bulk-upload" ||
                      pathname === "/file-processes" ||
                      pathname === "/employer-processes",
                  })}
                >
                  <CopyProvider
                    page={"navigation (left)"}
                    type={"label"}
                    id={"files"}
                  />
                  <div className={classes.unreadCount}>
                    <UnreadFilesCount />
                  </div>
                </span>
              </ListItem>
            </Tooltip>
          </Link>
        </Permissions>
        <Collapse
          in={true}
          timeout="auto"
          unmountOnExit
          className={classnames("subNavLink-group", {
            [classes.groupHighlight]:
              pathname === "/files" ||
              pathname === "/upload-file" ||
              pathname === "/bulk-upload" ||
              pathname === "/file-processes" ||
              pathname === "/employer-processes",
          })}
        >
          <List disablePadding>
            <Permissions type={"FileUpload"} actions={["read"]}>
              <Link to="/upload-file">
                <ListItem button className={classes.nested}>
                  <span
                    className={classnames({
                      [classes.bold]: pathname === "/upload-file",
                      [classes.subNavLink]: true,
                    })}
                  >
                    <CopyProvider
                      page={"navigation (left)"}
                      type={"label"}
                      id={"uploadfil"}
                    />
                  </span>
                </ListItem>
              </Link>
            </Permissions>
            <Permissions type={"BulkUpload"} actions={["read"]}>
              <Link to="/bulk-upload">
                <ListItem button className={classes.nested}>
                  <span
                    className={classnames({
                      [classes.bold]: pathname === "/bulk-upload",
                      [classes.subNavLink]: true,
                    })}
                  >
                    <CopyProvider
                      page={"navigation (left)"}
                      type={"label"}
                      id={"bulkupload"}
                    />
                  </span>
                </ListItem>
              </Link>
            </Permissions>
            <Permissions type={"FileProcess"} actions={["read"]}>
              <Link to="/file-processes">
                <ListItem button className={classes.nested}>
                  <span
                    className={classnames({
                      [classes.bold]: pathname === "/file-processes",
                      [classes.subNavLink]: true,
                    })}
                  >
                    <CopyProvider
                      page={"navigation (left)"}
                      type={"label"}
                      id={"fileproce"}
                    />
                  </span>
                </ListItem>
              </Link>
            </Permissions>
            <Permissions type={"EmployerProcess"} actions={["read"]}>
              <Link to="/employer-processes">
                <ListItem button className={classes.nested}>
                  <span
                    className={classnames({
                      [classes.bold]: pathname === "/employer-processes",
                      [classes.subNavLink]: true,
                    })}
                  >
                    <CopyProvider
                      page={"navigation (left)"}
                      type={"label"}
                      id={"employerp"}
                    />
                  </span>
                </ListItem>
              </Link>
            </Permissions>
          </List>
        </Collapse>
      </List>
    </Drawer>
  );
}
