export const SET_MODAL_OPEN = "SET_MODAL_OPEN";
export const SET_MODAL_CLOSE = "SET_MODAL_CLOSE";

export const setModalOpen = (modalType, modalProps) => ({
  type: SET_MODAL_OPEN,
  payload: {
    modalType,
    modalProps
  }
});

export const setModalClose = () => ({
  type: SET_MODAL_CLOSE
});
