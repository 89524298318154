import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import AppLayout from "./layouts/AppLayout/AppLayout";
import Permissions from "./components/Permissions/Permissions";
import NoAvailablePermissions from "./views/NoAvailablePermissions/NoAvailablePermissions";
import { Search } from "./views/Search/Search";

const Dashboard = React.lazy(() => import("./views/Dashboard/Dashboard"));
const Files = React.lazy(() => import("./views/Files/Files"));
const UploadFile = React.lazy(() => import("./views/UploadFile/UploadFile"));
const BulkUpload = React.lazy(() => import("./views/BulkUpload/BulkUpload"));
const FileProcesses = React.lazy(() =>
  import("./views/FileProcesses/FileProcesses")
);
const EmployerProcesses = React.lazy(() =>
  import("./views/EmployerProcesses/EmployerProcesses")
);
const PageNotFound = React.lazy(() =>
  import("./views/PageNotFound/PageNotFound")
);

const Routes = () => {
  return (
    <Switch>
      <Route path="/:path(|#.*|dashboard)">
        <Permissions type={"Portal"} actions={["read"]}>
          <Redirect to="/no-permissions" />
          <AppLayout>
            <Dashboard />
          </AppLayout>
        </Permissions>
      </Route>
      {<Route exact path="/search">
        <Permissions type={"Portal"} actions={["read"]}>
          <Redirect to="/no-permissions" />
          <AppLayout hideEmployerBoardSelector={true}>
            <Search />
          </AppLayout>
        </Permissions>
      </Route>}
      <Route exact path="/files">
        <Permissions type={"Portal"} actions={["read"]}>
          <Redirect to="/no-permissions" />
          <AppLayout>
            <Files />
          </AppLayout>
        </Permissions>
      </Route>
      <Route exact path="/upload-file">
        <Permissions type={"Portal"} actions={["read"]}>
          <Redirect to="/no-permissions" />
          <AppLayout>
            <UploadFile />
          </AppLayout>
        </Permissions>
      </Route>
      <Route exact path="/bulk-upload">
        <Permissions type={"Portal"} actions={["read"]}>
          <Redirect to="/no-permissions" />
          <AppLayout>
            <BulkUpload />
          </AppLayout>
        </Permissions>
      </Route>
      <Route exact path="/file-processes">
        <Permissions type={"Portal"} actions={["read"]}>
          <Redirect to="/no-permissions" />
          <AppLayout>
            <FileProcesses />
          </AppLayout>
        </Permissions>
      </Route>
      <Route exact path="/employer-processes">
        <Permissions type={"Portal"} actions={["read"]}>
          <Redirect to="/no-permissions" />
          <AppLayout>
            <EmployerProcesses />
          </AppLayout>
        </Permissions>
      </Route>
      <Route exact path="/no-permissions">
        <AppLayout>
          <NoAvailablePermissions />
        </AppLayout>
      </Route>
      <Route>
        <AppLayout>
          <PageNotFound />
        </AppLayout>
      </Route>
    </Switch>
  );
};

export default Routes;
