import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

function StackMessage() {
  const messageObj = useSelector((state) => state.message);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const action = useCallback(
    (key) => (
      <>
        <IconButton
          onClick={() => {
            closeSnackbar(key);
          }}
          style={{ color: "#fff" }}
        >
          <CloseIcon />
        </IconButton>
      </>
    ),
    [closeSnackbar]
  );

  useEffect(() => {
    if (messageObj.message !== "") {
      enqueueSnackbar(messageObj.message, {
        autoHideDuration:
          messageObj.severity === "error" || messageObj.severity === "warning"
            ? 10000
            : 5000,
        variant: messageObj.severity,
        action,
      });
    }
  }, [messageObj, action, enqueueSnackbar]);

  return <div></div>;
}

export default StackMessage;
