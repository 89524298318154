import { getDataFromAPI } from "../utils/api";
import { setErrorMessage } from "./index";

export const SET_EMPLOYER_LIST = "SET_EMPLOYER_LIST";

export const setEmployerList = (employerList) => {
  return {
    type: SET_EMPLOYER_LIST,
    payload: employerList,
  };
};

export const fetchEmployerList = () => {
  return (dispatch, getState) => {
    const getEmployerList = async () => {
      const result = await getDataFromAPI(`/employer/list`);
      if (result instanceof Error) {
        if (
          result.response &&
          result.response.data &&
          result.response.data !== ""
        ) {
          dispatch(setErrorMessage(result.response.data.code));
        } else {
          dispatch(setErrorMessage("OE000"));
        }
      } else {
        if (result) {
          dispatch(setEmployerList(result));
        }
      }
    };
    getEmployerList();
  };
};
