export default {
  root: {
    paddingTop: "8.5px",
    paddingBottom: "8.5px",
    "&$selected": {
      backgroundColor: "rgba(238,248,255,0.5)",
      "&:hover": {
        backgroundColor: "rgba(238,248,255,0.5)",
      },
    },
  },
};
