import React, { useState } from "react";
import { TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  row: {
    marginBottom: theme.spacing(2),
  },
  required: {
    color: theme.palette.error.light,
  },
  label: {
    ...theme.typography.h6,
    marginBottom: theme.spacing(1),
  },
}));

const ProcessDescription = React.memo(function ProcessDescription(props) {
  const classes = useStyles();
  const { dispatch, description, frenchDescription } = props;
  const [descriptionError, setDescriptionError] = useState("");
  const [frenchDescriptionError, setFrenchDescriptionError] = useState("");

  function render(label, inputId, value, dispatchType) {
    return <div className={classes.row}>
      <Typography className={classes.label}>{label}</Typography>
      <TextField
        id={inputId}
        aria-label={label}
        error={descriptionError !== ""}
        helperText={descriptionError}
        variant="outlined"
        fullWidth
        size="small"
        value={value}
        inputProps={{ "data-testid": inputId, maxLength: 255 }}
        multiline
        minRows={4}
        onChange={(e) => {
          dispatch({
            type: dispatchType,
            payload: e.target.value,
          });
        }}
      ></TextField>
    </div>;
  }

  return (
    <div>
      {render("Process Description (English)", "process-description-en", description, "SET_DESCRIPTION")}
      {render("Process Description (French)", "process-description-fr", frenchDescription, "SET_FRENCH_DESCRIPTION")}
    </div>
  );
});

ProcessDescription.propTypes = {
  dispatch: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  frenchDescription: PropTypes.string.isRequired,
};

export default ProcessDescription;
