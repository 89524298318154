export default {
  root: {
    "&$selected": {
      backgroundColor: "rgba(238,248,255,0.5)",
      "&:hover": {
        backgroundColor: "rgba(238,248,255,0.5)"
      }
    }
  }
};
