import palette from "../palette";
import typography from "../typography";

export default {
  root: {
    ...typography.body1,
    borderBottom: `1px solid ${palette.divider}`,
    padding: "10px 16px",
  },
  head: {
    fontWeight: 600,
  },
  stickyHeader: {
    backgroundColor: "#fafafa",
  },
};
