import { SET_AUTHENTICATION_STATUS } from "../actions/index";

const authReducer = (state = false, action) => {
  switch (action.type) {
    case SET_AUTHENTICATION_STATUS: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

export default authReducer;
