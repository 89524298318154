export default {
  root: {
    fontWeight: 600,
    fontSize: ".875rem",
    lineHeight: 1.5,
    textTransform: "initial",
    "&:hover": {
      backgroundColor: "#ebf0f4",
    },
  },
  outlined: {
    backgroundColor: "#ffffff",
    "&:disabled": {
      border: "2px solid #c8c8c8",
      background: "#e1e1e1",
      color: "#2d2d2d",
      opacity: 0.65,
    },
  },
  outlinedPrimary: {
    border: "2px solid  #d9a867",
    borderRadius: "19px",
    padding: ".375rem 1.5rem",
    color: "#2d2d2d",
    "&:hover": {
      border: "2px solid  #d9a867",
      backgroundColor: "#f7eee1",
      cursor: "pointer",
    },
    "&:focus": {
      border: "2px solid #d9a867",
      backgroundColor: "#f7eee1",
      boxShadow: "0 0 0 0.2rem rgba(189,148,94,.5)",
    },
    "&:active": {
      border: "2px solid #cc8b34",
      color: "#212529",
      backgroundColor: "#cf913e",
    },
  },
  outlinedSecondary: {
    border: "2px solid #366b92",
    borderRadius: "19px",
    padding: ".375rem 1.5rem",
    color: "#2d2d2d",
    "&:hover": {
      border: "2px solid #366b92",
      backgroundColor: "#ebf0f4",
      cursor: "pointer",
    },
    "&:focus": {
      border: "2px solid #366b92",
      backgroundColor: "#ebf0f4",
      boxShadow: "0 0 0 0.2rem rgba(84,129,162,.5)",
    },
    "&:active": {
      border: "2px solid #254963",
      color: "#2d2d2d",
      backgroundColor: "#28506d",
    },
  },
};
