import React, { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import SearchTableResults from "./SearchTableResults";
import SearchForm from "./SearchForm";
import { Container, LinearProgress, Paper } from "@material-ui/core";
import PagePath from "../../components/PagePath/PagePath";
import CopyProvider from "../../components/CopyProvider/CopyProvider";
import { getDataFromAPI } from "../../utils/api";
import { setErrorMessage } from "../../actions/messageActions";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    // paddingBottom: theme.spacing(3),
    minHeight: "calc(100vh - 64px - 24px - 53px)",
  },
  container: {
    marginLeft: 0,
    marginRight: 0,
    paddingLeft: theme.spacing(5),
  },
  navItem: {
    minWidth: "auto",
    marginTop: "2px",
    "&:not(.Mui-selected):hover": {
      color: theme.palette.text.primary,
    },
    "&.Mui-selected": {
      color: "#366b92",
      "& i": {
        color: "#d9a867",
      },
      backgroundColor: "#fff",
      border: "1px solid transparent",
      borderTopWidth: "2px",
      borderBottomWidth: "0px",
      borderColor: "#dee2e6 #dee2e6 #eee",
      borderTopColor: "#d9a867",
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      marginTop: "0px",
      "&:first-child": {
        marginLeft: "-1px",
      },
    },
  },
  paper: {
    marginTop: "2rem",
  },
  navWrapper: {
    marginBottom: theme.spacing(4),
    display: "flex",
    justifyContent: "space-between",
  },
  navLink: {
    padding: "0 0.5rem",
    color: theme.palette.text.primary,
  },
  navLinkIcon: {
    fontSize: "22px",
    lineHeight: 1,
    color: theme.palette.text.primary,
  },
  navText: {
    padding: "0 0.5rem",
  },
  tabs: {
    backgroundColor: "#f7f7f7",
    borderBottom: "1px solid #dee2e6",
    "& .MuiTabs-indicator": {
      display: "none",
    },
  },
  tabLabel: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& i": {
      fontSize: "20px",
      marginRight: "8px",
    },
    "& span": {
      fontWeight: 600,
    },
  },
}));

/**
 * @param {Object} props
 * @returns the Search React Hook
 */
export function Search({}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [results, setResults] = useState([]);
  const [lastSearch, setLastSearch] = useState({
    board: null,
    dateWithin: null,
    directory: null,
    fileName: null,
    fileProcess: null,
    folders: null,
    fromDate: null,
    toDate: null
  });
  const [isLoading, setIsLoading] = useState(false);
  const searchTableResultsRef = useRef();

  function viewModel(result) {
    return {
      ...result,
      key: result.id
    };
  }

  /**
   * 
   * @param {object} search a list of search criteria
   * @param {string | void} search.board
   * @param {Date | void} search.dateWithin
   * @param {string | void} search.directory
   * @param {string | void} search.fileName
   * @param {string | void} search.fileProcess
   * @param {Array | void} search.folders
   * @param {Date | void} search.fromDate
   * @param {Date | void} search.toDate
   */
  async function onSearch(search) {
    searchTableResultsRef.current.deselectAll();
    setLastSearch(search);
    const queryParamList = [];
    if (search) {
      if (search.board) {
        queryParamList.push(`boardNumber=${search.board}`);
      }
      if (search.dateWithin) {
        queryParamList.push(`dateWithin=${search.dateWithin}`);
      }
      if (search.directory) {
        queryParamList.push(`directory=${search.directory}`);
      }
      if (search.fileName) {
        queryParamList.push(`fileName=${search.fileName}`);
      }
      if (search.fileProcess) {
        queryParamList.push(`fileProcess=${search.fileProcess}`);
      }
      if (search.folders) {
        search.folders.forEach(x => {
          queryParamList.push(`folder=${x}`);
        });
      }
      if (search.fromDate) {
        queryParamList.push(`fromDate=${search.fromDate.toISOString()}`);
      }
      if (search.toDate) {
        queryParamList.push(`toDate=${search.toDate.toISOString()}`);
      }
      if (!search.dateWithin && !search.fromDate && !search.toDate) {
        //Default for Search page load
        queryParamList.push(`dateWithin=30`);
      }
    }
    const queryParams = queryParamList.join("&");
    const apiURL = `/search/?${queryParams}`;
    setIsLoading(true);
    try {
      const result = await getDataFromAPI(apiURL);
      if (result instanceof Error) {
        if (result.response && result.response.data) {
          dispatch(setErrorMessage(result.response.data.code));
        } else {
          dispatch(setErrorMessage("OE000"));
        }
      } else if (result) {
        setResults(result);
      }
    } finally {
      setIsLoading(false);
    }
  }

  const [ toggleBoardColumn, setToggleBoardColumn ] = useState(false);

  const toggleBoardColumnChanged = useCallback(() => {
    setToggleBoardColumn(!toggleBoardColumn);
  }, [toggleBoardColumn]);

  return <div className={classes.root}>
    <Container maxWidth="lg" className={classes.container}>
      <div className={classes.navWrapper}>
        <div>
          <PagePath
            iconName="fa-search"
            rootPath="Search"
            displayRootPath={
              <CopyProvider
                page={"navigation (left)"}
                type={"label"}
                id={"search"}
              />
            }
          />
        </div>
      </div>
      <Paper square className={classes.paper}>
        <SearchForm update={onSearch} />
        {isLoading && <LinearProgress />}
        <SearchTableResults fetchHandler={async () => await onSearch(lastSearch)} ref={searchTableResultsRef} rows={results.map(r => viewModel(r))} toggleBoardColumn={toggleBoardColumn} toggleBoardColumnChanged={toggleBoardColumnChanged} />
      </Paper>
    </Container>
  </div>;
}

Search.propTypes = {
};
