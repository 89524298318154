import { UserAgentApplication } from "msal";
import {
  authConfig,
  appConfig,
} from "../configs/config";

// Create the userAgentApplication instance
const userAgentApplication = new UserAgentApplication(authConfig);
const redirectCallback = (error, response) => {
  if (error) {
    if (error.errorMessage.indexOf("AADB2C90118") > -1) {
      changePassword();
    }
  }
};
userAgentApplication.handleRedirectCallback(redirectCallback);

// ***********Login************//
export function login() {
  let language = null;
  const originUrl = window.location.origin;
  if (originUrl.includes(appConfig.frenchOriginUrl)) {
    language = "fr";
  }
  else if (originUrl.includes(appConfig.englishOriginUrl)){
    language = "en";
  }
  const loginRequest = {
    scopes: appConfig.b2cScopes,
    extraQueryParameters: language !== null ? { ui_locales: language } : null
  };
  userAgentApplication.loginRedirect(loginRequest);
}
// ***********Logout************//
export function logout() {
  userAgentApplication.logout();
  localStorage.removeItem("school_board_number");
}
// ***********Change Password************//
export function changePassword() {
  window.location.href = appConfig.b2cPolicies.authorities.forgotPassword;
}
// ***********Get Access Token************//
export async function getAccessToken() {
  const tokenRequest = {
    scopes: appConfig.b2cScopes,
  };
  try {
    const accessToken = await userAgentApplication.acquireTokenSilent(
      tokenRequest
    );
    // console.log(accessToken);
    return accessToken.accessToken;
  } catch (err) {
    if (
      typeof err === "object" &&
      err.name === "InteractionRequiredAuthError"
    ) {
      try {
        const authCallback = (error, response) => {
          if (error) {
            console.log(error);
          }
        };
        userAgentApplication.handleRedirectCallback(authCallback);
        const accessToken = await userAgentApplication.acquireTokenRedirect(
          tokenRequest
        );
        if (accessToken) {
          return accessToken;
        }
      } catch (error) {
        console.log("fail to acquire token redirect ", error);
      }
    } else {
      console.log("fail to acquire token silently ", err);
    }
  }
}

export default userAgentApplication;
