import { SET_USER_INFO } from "../actions/index";

const userInfoReducer = (state = { name: "", id: "", group: "" }, action) => {
  switch (action.type) {
    case SET_USER_INFO: {
      return { ...action.payload };
    }
    default: {
      return state;
    }
  }
};

export default userInfoReducer;
