import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { Provider as StoreProvider } from "react-redux";
import userAgentApplication, { login } from "./utils/auth";
import configureStore from "./store/configureStore";
import App from "./App";
import "./utils/localization";
import { unregister as unregisterServiceWorker } from "./serviceWorker";
// import * as serviceWorker from "./serviceWorker";

const account = userAgentApplication.getAccount();
if (!account) {
  setTimeout(() => {
    login();
  }, 200); // Put in callback queue to make way for the reset password redirect callback
} else {
  const isIdTokenExpired = !account.idToken || (new Date(account.idToken.exp * 1000) < new Date());
  if (isIdTokenExpired) {
    userAgentApplication.logout();
    login();
  } else {
    const store = configureStore();
    ReactDOM.render(
      <StoreProvider store={store}>
        <App />
      </StoreProvider>,
      document.getElementById("root")
    );
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
unregisterServiceWorker();
