import React from "react";
import Badge from "@material-ui/core/Badge";
import { makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";
import classnames from "classnames";

const useStyles = makeStyles(() => ({
  badge: {
    margin: "0 8px 0 18px ",
  },
}));

export default function UnreadFilesCount(props) {
  const classes = useStyles();
  const unreadFilesCount = useSelector((state) => state.unreadFilesCount);

  return (
    <Badge
      badgeContent={unreadFilesCount}
      className={classnames({ [classes.badge]: unreadFilesCount > 0 })}
      overlap="circular"
    ></Badge>
  );
}
