import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import classnames from "classnames";
import { Button, FormControl, lighten, makeStyles, Switch, Toolbar, Tooltip, Typography } from "@material-ui/core";
import CopyProvider from "../../components/CopyProvider/CopyProvider";
import SelectedFilesCounter from "../Files/SelectedFilesCounter";
import Permissions from "../../components/Permissions/Permissions";
import { maximumFileDownload } from "../../configs/config";
import { FormControlLabel } from "../../../node_modules/@material-ui/core/index";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
  downloadSelectedButton: {
    marginLeft: "12px",
  },
  buttonIcon: {
    fontSize: "20px",
    marginRight: theme.spacing(1),
  },
}));

/**
 * @param {Object} props
 * @param {Function} props.download The delegate callback for the Download Selected button
 * @param {Number} props.numSelected The number of selected rows
 * @param {Boolean} props.toggleBoardColumn Display the Board column
 * @param {Function} props.toggleBoardColumnChanged Toggle board column delegate callback
 * @returns The Search Table Toolbar React Hook
 */
export default function SearchTableToolbar({ download, numSelected, toggleBoardColumn, toggleBoardColumnChanged }) {
  const classes = useToolbarStyles();

  const selectedFileCount = numSelected;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <Permissions type={"FileDownload"} actions={["read"]}>
        <>
          <Tooltip
            open={selectedFileCount > maximumFileDownload}
            placement="bottom-start"
            arrow
            title={
              <CopyProvider
                page={"files"}
                type={"tool tip"}
                id={"youcanon"}
                inserts={[maximumFileDownload]}
              />
            }
            aria-label={
              <CopyProvider
                page={"files"}
                type={"tool tip"}
                id={"youcanon"}
                inserts={[maximumFileDownload]}
              />
            }
          >
            <span>
              <Button
                color="primary"
                className={classes.downloadSelectedButton}
                disabled={
                  selectedFileCount === 0 ||
                  selectedFileCount > maximumFileDownload
                }
                onClick={() => download()}
              >
                <i
                  className={classnames("fal fa-arrow-alt-to-bottom", {
                    [classes.buttonIcon]: true,
                  })}
                ></i>
                <CopyProvider
                  page={"files"}
                  type={"label"}
                  id={"downloads"}
                />
              </Button>
              <SelectedFilesCounter count={selectedFileCount} />
            </span>
          </Tooltip>
        </>
      </Permissions>
      <FormControl className={classes.formControl}>
        <Typography className={classes.label}>
          <FormControlLabel
            control={<Switch
              checked={toggleBoardColumn}
              onChange={toggleBoardColumnChanged}
              name="toggleBoardColumn"
              color="primary"
            />}
            label={<CopyProvider page={"files"} type={"label"} id={"displayboard"} />}
            labelPlacement="end"
          />
        </Typography>
      </FormControl>
    </Toolbar>
  );
};

SearchTableToolbar.propTypes = {
  download: PropTypes.func.isRequired,
  numSelected: PropTypes.number.isRequired,
};
