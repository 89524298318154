import React, { Suspense } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { LinearProgress } from "@material-ui/core";
import TopBar from "./TopBar";
import NavBar from "./NavBar";
import Footer from "./Footer";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "100vh",
    display: "flex",
    backgroundImage: "url(/images/page-bg.jpg)",
    backgroundPosition: "100% 100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    "@media all and (-ms-high-contrast:none)": {
      height: 0, // IE11 fix
    },
  },
  content: {
    position: "relative",
    flexGrow: 1,
    maxWidth: "100%",
    minHeight: "100vh",
    overflowX: "hidden",
    paddingTop: 64,
    // paddingBottom: 20,
    [theme.breakpoints.down("xs")]: {
      paddingTop: 56,
    },
  },
}));

/**
 * 
 * @param {Object} props
 * @param {React.ReactElement} props.children HTML DOM
 * @param {Boolean} props.hideEmployerBoardSelector Hide the employer selector in the Top Bar component
 * @returns The App Layout React hook
 */
function AppLayout({ children, hideEmployerBoardSelector }) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <NavBar />
      <div className={classes.content}>
        <TopBar hideEmployerBoardSelector={hideEmployerBoardSelector} />
        <Suspense fallback={<LinearProgress />}>{children}</Suspense>
        <Footer />
      </div>
    </div>
  );
}

AppLayout.propTypes = {
  route: PropTypes.object,
};

export default AppLayout;
