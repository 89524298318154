import { colors } from "@material-ui/core";

const white = "#FFFFFF";

export default {
  primary: {
    // contrastText: white,
    // dark: colors.indigo[900],
    // main: colors.indigo[500],
    // light: colors.indigo[100]
    //teal
    main: "#366b92",
  },
  secondary: {
    // contrastText: white,
    // dark: colors.blue[900],
    // main: colors.blue.A700,
    // light: colors.blue.A400
    //orange
    main: "#d9a867",
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  text: {
    primary: "#2d2d2d",
    secondary: "#546e7a",
    label: "#366b92",
    link: colors.blue[600],
  },
  link: "#366b92",
  icon: colors.blueGrey[600],
  background: {
    default: "#eee",
    paper: white,
  },
  divider: colors.grey[200],
};
