import React, { useEffect, useCallback } from "react";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/styles";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { setEmployerInfo } from "../../actions/index";
const useStyles = makeStyles((theme) => ({
  textField: {
    minWidth: "488px",
    "& input": {
      padding: "3px !important",
    },
  },
  select: {
    background: "#fff",
    "& select": {
      paddingTop: "12px",
      paddingBottom: "12px",
      paddingLeft: "40px",
    },
  },
  icon: {
    width: "1.5rem",
    height: "1.5rem",
    overflow: "hidden",
    fontSize: "1rem",
    flexShrink: 0,
    userSelect: "none",
    position: "absolute",
    marginTop: "12px",
    marginLeft: "11px",
    zIndex: 1,
  },
}));

function EmployerSelector({ ...rest }) {
  const dispatch = useDispatch();
  const employerList = useSelector((state) => state.employerList, _.isEqual);
  const employerInfo = useSelector((state) => state.employerInfo, _.isEqual);
  const classes = useStyles();

  const handleChange = useCallback(
    (e, value) => {
      const schoolBoardNumber = value.id;
      const employerInfo = employerList.find(
        (employer) => employer.id === schoolBoardNumber
      );
      dispatch(setEmployerInfo(employerInfo));
      localStorage.setItem("school_board_number", schoolBoardNumber);
    },
    [employerList, dispatch]
  );

  useEffect(() => {
    // When user refreshes the page, retrieve the schoolBoardNumber from localStorage if it's available in there
    if (employerList.length > 0) {
      if (localStorage.getItem("school_board_number")) {
        const schoolBoardNumber = localStorage.getItem("school_board_number");
        const employerInfo = employerList.find(
          (employer) => employer.id === schoolBoardNumber
        );
        dispatch(setEmployerInfo(employerInfo));
      }
    }
  }, [employerList, dispatch]);

  if (employerList.length > 0) {
    return (
      <Autocomplete
        {...rest}
        disableClearable
        options={employerList}
        getOptionLabel={(employer) =>
          employer.id === ""
            ? "Search or select an Employer"
            : `${employer.id} -- ${employer.name}`
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            className={classes.textField}
          />
        )}
        onChange={handleChange}
        value={employerInfo}
        getOptionSelected={(option, value) => option.id === value.id}
      />
    );
  } else {
    return <Skeleton variant="rect" width={500} height={40} />;
  }
}

export default EmployerSelector;
