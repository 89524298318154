import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Container, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    // paddingBottom: theme.spacing(3),
    minHeight: "calc(100vh - 64px - 24px - 53px)",
  },
  container: {
    marginLeft: theme.spacing(2),
    marginRight: 0,
  },
}));

export default function NoAvailablePermissions() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container maxWidth="lg" className={classes.container}>
        <Typography variant="h1">No Available Permissions</Typography>
      </Container>
    </div>
  );
}
