import React, { useEffect } from "react";
import { getDataFromAPI } from "../../utils/api";
import { useDispatch, useSelector } from "react-redux";
import { setPermissions } from "../../actions/index";
import { setErrorMessage } from "../../actions/index";

let multiLoadBlocker = false; // stops the AJAX call from going off for every call. The useEffect run-once method is not fast enough to stop multiple calls being made

/*
  havePermission() uses prps.type and props.action to filter through permissions (a Map).
  there must be the same number of permissions as there are actions passed through props.
  havePermission() also, given hasPermissions is true, executes a function passed through props (props.runWithPermission)

  getPermissions() hit the API to ge teh list of permission. These return as an Array of strings which are parsed by
  getPermissions() and turned into a Map object and added to the global store for use by havePermission()

  renderComponent() is called by the return and outputs either the permissionGranted OR the permissionDenied JSX constructs.
  The JSX constructs can both be passed the props.children OR the permissionDenied can default to null..
  This allows for the choice of permissionGranted and permissionDenied to be determined where the <Permissions> used

 */

/**
 * Return true iff all actions are subset of permissions.
 */
export function hasPermission(permissions, actions, type)
{
  let hasPermissions = false;
  if (permissions && permissions.size > 0) {
    const permissionList = actions.filter((action) => {
      return permissions.has(type.toLowerCase())
        ? permissions.get(type.toLowerCase()).includes(action)
        : false;
    });
    hasPermissions = permissionList.length === actions.length;
  }
  return hasPermissions;
}

export default function Permissions(props) {
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state.permissions);
  const children = React.Children.toArray(props.children);
  const permissionGranted = children[1] ? children[1] : children[0];
  const permissionDenied = children[1] ? children[0] : null;

  function havePermission() {
    //add logic using the permissions data gained from API
    let hasPermissions = hasPermission(permissions, props.actions, props.type);
    if (hasPermissions && props.runWithPermission) {
      props.runWithPermission();
    }
    return hasPermissions;
  }

  async function getPermissions() {
    const apiURL = "/Security/permissions";
    const filesData = await getDataFromAPI(apiURL);
    if (filesData instanceof Error) {
      if (filesData.response && filesData.response.data) {
        dispatch(setErrorMessage(filesData.response.data.code));
      } else {
        dispatch(setErrorMessage("OE000"));
      }
    } else if (filesData) {
      const newPermissions = new Map(permissions);
      filesData.forEach((permissionSet) => {
        const set = permissionSet.split("/");
        const key = set[0].toLowerCase();
        const value = set[1].toLowerCase();
        if (newPermissions.has(key)) {
          const currentValueArray = newPermissions.get(key);
          if (!currentValueArray.includes(value)) {
            currentValueArray.push(value);
            newPermissions.set(key, currentValueArray);
          }
        } else newPermissions.set(key, [value]);
      });
      dispatch(setPermissions(newPermissions));
    }
  }

  useEffect(() => {
    if (permissions === null && !multiLoadBlocker) {
      getPermissions();
      multiLoadBlocker = true;
    }
  });

  function renderComponent() {
    let component = null;
    if (permissions !== null) {
      if (havePermission()) {
        component = permissionGranted;
      } else {
        component = permissionDenied;
      }
    }
    return component;
  }

  return renderComponent();
}
