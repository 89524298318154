import React, { useState, useCallback } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  Typography,
  Badge,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import PropTypes from "prop-types";
import _ from "lodash";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "540px",
    position: "relative",
  },
  counter: {
    marginLeft: "16px",
    marginTop: "-3px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "12px",
  },
  select: {
    minWidth: "160px",
  },
  legend: {
    ...theme.typography.h6,
    marginBottom: theme.spacing(1),
  },
  required: {
    color: theme.palette.error.light,
  },
  tableContainer: {
    height: "88%",
  },
  checkbox: {
    paddingLeft: "0px",
  },
  bold: {
    ...theme.typography.h6,
  },
  employerName: {
    maxWidth: "340px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}));

const EmployerList = React.memo(function EmployerList(props) {
  const classes = useStyles();
  const allEmployerList = useSelector((state) => state.employerList);
  const { dispatch, selectedEmployerList, reason } = props;
  const [employerListStatus, setEmployerListStatus] = useState("selected");
  const [filteredEmployerList, setFilteredEmployerList] = useState(() => {
    if (reason.toLowerCase() === "create") {
      return allEmployerList;
    } else {
      return allEmployerList.filter((employer) =>
        _.some(selectedEmployerList, ["id", employer.id])
      );
    }
  });

  const handleSelectAll = useCallback(
    (e) => {
      const employerIdList = allEmployerList.map((employer) => ({
        id: employer.id,
      }));
      const newSelectedList = e.target.checked ? [...employerIdList] : [];
      dispatch({
        type: "SET_EMPLOYER_LIST",
        payload: newSelectedList,
      });
    },
    [allEmployerList, dispatch]
  );

  const handleSelectOne = useCallback(
    (e, selectedEmployer) => {
      const selectedIndex = _.findIndex(
        selectedEmployerList,
        (employer) => employer.id === selectedEmployer.id
      );
      let newSelectedList = [];

      if (selectedIndex === -1) {
        newSelectedList = newSelectedList.concat(
          selectedEmployerList,
          selectedEmployer
        );
      } else if (selectedIndex === 0) {
        newSelectedList = newSelectedList.concat(selectedEmployerList.slice(1));
      } else if (selectedIndex === selectedEmployerList.length - 1) {
        newSelectedList = newSelectedList.concat(
          selectedEmployerList.slice(0, -1)
        );
      } else if (selectedIndex > 0) {
        newSelectedList = newSelectedList.concat(
          selectedEmployerList.slice(0, selectedIndex),
          selectedEmployerList.slice(selectedIndex + 1)
        );
      }

      dispatch({
        type: "SET_EMPLOYER_LIST",
        payload: newSelectedList,
      });
    },
    [selectedEmployerList, dispatch]
  );

  const handleDropdownChange = useCallback(
    (e) => {
      setEmployerListStatus(e.target.value);
      switch (e.target.value) {
        case "all": {
          setFilteredEmployerList(allEmployerList);
          break;
        }
        case "selected": {
          setFilteredEmployerList(
            allEmployerList.filter((employer) =>
              _.some(selectedEmployerList, ["id", employer.id])
            )
          );
          break;
        }
        case "unselected": {
          setFilteredEmployerList(
            allEmployerList.filter(
              (employer) => !_.some(selectedEmployerList, ["id", employer.id])
            )
          );
          break;
        }
        default:
          setFilteredEmployerList(allEmployerList);
      }
    },
    [allEmployerList, selectedEmployerList]
  );

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography className={classes.legend}>
          Select Employers <span className={classes.required}>*</span>
          <Badge
            badgeContent={selectedEmployerList.length}
            className={classes.counter}
            overlap="circular"
          ></Badge>
        </Typography>
        {reason.toLowerCase() === "update" && (
          <FormControl variant="outlined" className={classes.select}>
            <Select
              autoWidth={false}
              value={employerListStatus}
              onChange={handleDropdownChange}
              inputProps={{
                name: "employer-selector-status",
                "aria-label": "employer-selector-status",
              }}
            >
              <MenuItem value="all">All Employers</MenuItem>
              <MenuItem value="selected">Selected Only</MenuItem>
              <MenuItem value="unselected">Unselected Only</MenuItem>
            </Select>
          </FormControl>
        )}
      </div>
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader aria-label="sticky table" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  className={classes.checkbox}
                  checked={
                    selectedEmployerList.length !== 0 &&
                    selectedEmployerList.length === allEmployerList.length
                  }
                  color="primary"
                  indeterminate={
                    selectedEmployerList.length > 0 &&
                    selectedEmployerList.length < allEmployerList.length
                  }
                  onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell>Number</TableCell>
              <TableCell>Name</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {filteredEmployerList.map((employer) => {
              const checked = _.some(selectedEmployerList, ["id", employer.id]);
              return (
                <TableRow
                  hover
                  key={employer.id}
                  selected={checked}
                  onClick={(event) =>
                    handleSelectOne(event, { id: employer.id })
                  }
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      className={classes.checkbox}
                      checked={checked}
                      color="primary"
                      value={checked}
                    />
                  </TableCell>
                  <TableCell>{employer.id}</TableCell>
                  <TableCell>
                    <div className={classes.employerName}>{employer.name}</div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
});

EmployerList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  selectedEmployerList: PropTypes.array.isRequired,
  reason: PropTypes.string.isRequired,
};

export default EmployerList;
