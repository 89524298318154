import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Button, InputLabel, FormControl, FormLabel, FormControlLabel, MenuItem, RadioGroup, Radio, Select, TextField, Typography } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/styles";
import CopyProvider from "../../components/CopyProvider/CopyProvider";
import { getDataFromAPI } from "../../utils/api";
import { setErrorMessage } from "../../actions/messageActions";
import { useDispatch, useSelector } from "react-redux";
import { Box, Checkbox, Input, ListItemText, Switch } from "@material-ui/core";
import _ from "lodash";
import dayjs from "dayjs";
import { getDefaultFromDate, getDefaultToDate } from "../../utils/time";
import { implies } from "../../utils/logic";

export const DateFormat = "YYYY-MM-DD";

//Todo: Replace with datejs.min after upgrading to dayjs 1.11.X
export function dayMin(a, b) {
  if (!a || !(a instanceof dayjs) || !b || !(b instanceof dayjs)) {
    return undefined;
  }
  if (a.diff(b) < 0) {
    return a;
  }
  return b;
}

//Todo: Replace with datejs.max after upgrading to dayjs 1.11.X
export function dayMax(a, b) {
  if (!a || !(a instanceof dayjs) || !b || !(b instanceof dayjs)) {
    return undefined;
  }
  if (a.diff(b) > 0) {
    return a;
  }
  return b;
}

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    justifyContent: "space-between",
    padding: "40px 30px 16px 30px",
    background: "#fff",
    borderTop: "1px solid #eee",
    borderBottom: "1px solid #eee",
  },
  label: {
    ...theme.typography.h6,
    marginBottom: theme.spacing(1),
  },
  formControl: {
    // margin: theme.spacing(1),
    // minWidth: 120,
  },
  select: {
    width: "193px",
    lineHeight: "25px"
  }
}));

export const DateModes = {
  age: "age",
  dateRange: "dateRange"
};

export const InboxName = "Inbox";
export const SentName = "Sent";
export const TrashName = "Trash";

export const FolderOptions = [
  {
    id: "inbox",
    name: InboxName
  },
  {
    id: "sent",
    name: SentName
  },
  {
    id: "trash",
    name: TrashName
  },
];

/**
 * @param {Object} props
 * @param {Function} props.update The delegate callback to update the search results
 * @returns The Search Form React Hook
 */
export default function SearchForm({ update }) {

  const classes = useStyles();

  const [ fileName, setFileName ] = useState("");
  const [ fileProcess, setFileProcess ] = useState("");
  const [ dateWithin, setDateWithin ] = useState(30);
  const [ fromDate, setFromDate ] = useState(null);
  const [ toDate, setToDate ] = useState(null);
  const [ enableSubmit, setEnableSubmit ] = useState(false);
  const [ dateMode, setDateMode ] = useState(DateModes.age);
  const [ allFileProcesses, setAllFileProcesses ] = useState([]);
  const [ board, setBoard ] = useState("");
  const [ directory, setDirectory ] = useState("");

  const employerList = useSelector((state) => state.employerList, _.isEqual);
  const employerInfo = useSelector((state) => state.employerInfo, _.isEqual);
  const userGroup = useSelector((state) => state.userInfo.group);

  const isOtip = userGroup && userGroup.toLowerCase() === "otip";
  const employerFolderOptionBlacklist = ["trash"];
  const folderOptions = isOtip ?
    FolderOptions :
    FolderOptions.filter(x => !employerFolderOptionBlacklist.includes(x.id));
  const defaultFolders = isOtip ?
    [InboxName, SentName, TrashName] :
    [InboxName, SentName];
  const [ folders, setFolders ] = useState(defaultFolders);

  useEffect(() => {
    if (employerInfo && employerInfo.id) {
      setBoard(employerInfo.id);
    }
  }, [employerInfo]);
  
  const dispatch = useDispatch();

  const today = useMemo(() => {
    return dayjs(new Date());
  }, []);

  const minDate = useMemo(() => dayjs(new Date(1900, 0, 1)), []);

  const maxDate = useMemo(() => {
    const now = new Date();
    return dayjs(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59));
  }, []);

  function fileProcessInBoard(fileProcess) {
    return fileProcess.employerList.find(y => y.id === board) !== undefined;
  }

  function getDirectoryOptions() {
    return _.sortBy(_.uniq(allFileProcesses
      .filter(x => Boolean(x.fileDirectory) &&
        implies(board && x.employerList, fileProcessInBoard(x)))
        .map(x => x.fileDirectory)));
  }

  useEffect(() => {
    const validDate = fromDate === null || !Number.isNaN(Date.parse(fromDate));
    setEnableSubmit(validDate);
  }, [fileName, fileProcess, fromDate]);

  useEffect(() => {
    let mounted = true;
    const fetchFileProcesses = async () => {
      const result = await getDataFromAPI(
        `/fileprocess/listall`
      );
      if (result instanceof Error) {
        if (result.response && result.response.data) {
          dispatch(setErrorMessage(result.response.data.code));
        } else {
          dispatch(setErrorMessage("OE000"));
        }
      } else {
        if (mounted && result) {
          setAllFileProcesses(result);
        }
      }
    };

    // Clear current selected file Process
    setFileProcess("");
    // Fetch new file processes 
    fetchFileProcesses();

    return () => {
      mounted = false;
    };
  }, [board]);

  useEffect(() => {
    const resetFileProcess = fileProcess && !fileProcessInBoard(allFileProcesses.find(x => x.id === fileProcess));
    const resetDirectory = directory && allFileProcesses.filter(x => fileProcessInBoard(x) && x.fileDirectory === directory).length === 0;
    if (resetFileProcess) {
      setFileProcess("");
    }
    if (resetDirectory) {
      setDirectory("");
    }
  }, [board]);

  return (
    <Box display="flex" flexDirection="row" alignItems="stretch" flexWrap="wrap">
      <Box p={2}>
        <FormControl className={classes.formControl}>
          <FormLabel id="dateModeLabel">
            <Typography className={classes.label}>
              <CopyProvider page={"files"} type={"label"} id={"datelabel"} />
            </Typography>
          </FormLabel>
          <RadioGroup
            row
            id="dateMode"
            labelid="dateModeLabel"
            aria-label="Date Mode"
            name="dateMode"
            value={dateMode}
            onChange={e => {
              setDateMode(e.target.value);
              if (e.target.value === DateModes.age) {
                setDateWithin(30);
                setFromDate(null);
                setToDate(null);
              } else if (e.target.value === DateModes.dateRange) {
                setDateWithin(null);
                setFromDate(getDefaultFromDate(today));
                setToDate(getDefaultToDate(today));
              }
            }}
          >
            <FormControlLabel value={DateModes.age} control={<Radio color="primary" />} label={<CopyProvider page={"files"} type={"label"} id={"agelabel"} />} />
            <FormControlLabel value={DateModes.dateRange} control={<Radio color="primary" />} label={<CopyProvider page={"files"} type={"label"} id={"daterangelabel"} />} />
          </RadioGroup>
        </FormControl>
      </Box>
      {dateMode === DateModes.age && <Box p={2}>
        <FormControl className={classes.formControl}>
          <Typography className={classes.label}><CopyProvider page={"files"} type={"label"} id={"agelabel"} /></Typography>
          <Select
            value={dateWithin}
            onChange={e => setDateWithin(e.target.value)}
            name="viewDateWithin"
            className={classes.select}
          >
            <MenuItem value={30}>
              <CopyProvider
                page={"files"}
                type={"label"}
                id={"dateWithin"}
                inserts={["30"]}
              />
            </MenuItem>
            <MenuItem value={60}>
              <CopyProvider
                page={"files"}
                type={"label"}
                id={"dateWithin"}
                inserts={["60"]}
              />
            </MenuItem>
            <MenuItem value={90}>
              <CopyProvider
                page={"files"}
                type={"label"}
                id={"dateWithin"}
                inserts={["90"]}
              />
            </MenuItem>
          </Select>
        </FormControl>
      </Box>}
      {dateMode === DateModes.dateRange && <Box p={2}>
        <FormControl className={classes.formControl}>
          <Typography className={classes.label}><CopyProvider page={"files"} type={"label"} id={"from"} /></Typography>
          <KeyboardDatePicker
            autoOk
            variant="inline"
            inputVariant="outlined"
            format={DateFormat}
            InputAdornmentProps={{ position: "start" }}
            minDate={minDate}
            minDateMessage={`Minimum date: ${minDate.format(DateFormat)}`}
            maxDate={dayMax(dayMin(toDate, maxDate), minDate)}
            maxDateMessage={`Maximum date: ${toDate ? toDate.format(DateFormat) : maxDate.format(DateFormat)}`}
            value={fromDate}
            onChange={(x) => setFromDate(x)}
            placeholder={DateFormat}
          />
        </FormControl>
      </Box>}
      {dateMode === DateModes.dateRange && <Box p={2}>
        <FormControl className={classes.formControl}>
          <Typography className={classes.label}><CopyProvider page={"files"} type={"label"} id={"to"} /></Typography>
            <KeyboardDatePicker
              autoOk
              variant="inline"
              inputVariant="outlined"
              format={DateFormat}
              InputAdornmentProps={{ position: "start" }}
              minDate={dayMin(dayMax(fromDate, minDate), maxDate)}
              minDateMessage={`Minimum date: ${fromDate ? fromDate.format(DateFormat) : minDate.format(DateFormat)}`}
              maxDate={maxDate}
              maxDateMessage={`Maximum date: ${maxDate.format(DateFormat)}`}
              value={toDate}
              onChange={(x) => setToDate(x)}
              placeholder={DateFormat}
            />
        </FormControl>
      </Box>}
      {isOtip &&
        <Box p={2}>
          <FormControl className={classes.formControl}>
            <Typography className={classes.label}>
              <CopyProvider page={"files"} type={"label"} id={"employerboard"} />
            </Typography>
            <Select
              value={board}
              onChange={e => setBoard(e.target.value)}
              inputProps={{
                name: "board-field",
                "aria-label": "Board Field",
              }}
              autoWidth={false}
              className={classes.select}
              displayEmpty
              placeholder={"i.e. B66052"}
            >
              <MenuItem key={""} value="">All</MenuItem>
              {employerList.map(x => (
                <MenuItem
                  key={x.id}
                  value={x.id}
                >
                  {x.id} -- {x.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>}
      <Box p={2}>
        <FormControl className={classes.formControl}>
          <Typography className={classes.label}><CopyProvider page={"files"} type={"label"} id={"fileprocess"} /></Typography>
          <Select
            value={fileProcess}
            onChange={(e) => {
              const newFileProcess = e.target.value;
              const resetDirectory = !Boolean(e.target.value);
              const otherDirectory = newFileProcess && allFileProcesses.find(x => x.id === newFileProcess)?.fileDirectory !== directory;
              setFileProcess(e.target.value);
              if (resetDirectory) {
                setDirectory("");
              }
              else if (otherDirectory) {
                setDirectory(allFileProcesses.find(x => x.id === newFileProcess)?.fileDirectory);
              }
            }}
            inputProps={{
              name: "file-processes-field",
              "aria-label": "File Processes Field",
            }}
            autoWidth={false}
            className={classes.select}
            displayEmpty
          >
            <MenuItem key={""} value="">All</MenuItem>
            {allFileProcesses
              .filter(x => !Boolean(board) || x.employerList.find(y => y.id === board) !== undefined)
              .map(x => (
                <MenuItem
                  key={x.id}
                  value={x.id}
                >
                  {x.name}
                </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box p={2}>
        <FormControl className={classes.formControl}>
          <Typography className={classes.label}><CopyProvider page={"files"} type={"label"} id={"filename"} /></Typography>
          <TextField
            aria-label="Filename"
            variant="outlined"
            fullWidth
            size="small"
            value={fileName}
            onChange={e => {
              setFileName(e.target.value);
            }}
            inputProps={{ maxLength: 1000 }}
            placeholder="keyword"
          ></TextField>
        </FormControl>
      </Box>
      {userGroup && userGroup.toLowerCase() === "otip" &&
        <Box p={2}>
          <FormControl className={classes.formControl}>
            <Typography className={classes.label}><CopyProvider page={"files"} type={"label"} id={"directory"} /></Typography>
            <Select
              value={directory}
              onChange={(e) => {
                const newDirectory = e.target.value;
                const resetFileProcess = newDirectory && fileProcess && allFileProcesses.find(x => x.fileDirectory === newDirectory)?.id !== fileProcess;
                if (resetFileProcess) {
                  setFileProcess("");
                }
                setDirectory(e.target.value);
              }}
              inputProps={{
                name: "directory-field",
                "aria-label": "Directory Field",
              }}
              autoWidth={false}
              className={classes.select}
              displayEmpty
            >
              <MenuItem key={""} value="">All</MenuItem>
              {getDirectoryOptions().map(x => (
                <MenuItem key={x} value={x}>
                  {x}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>}
      <Box p={2}>
        <FormControl className={classes.formControl}>
          <Typography className={classes.label}>Folder</Typography>
          <Select
            labelId="folder-multiselect"
            id="folder-multiselect"
            multiple
            value={folders}
            onChange={(e) => {
              if (e.target.value.length === 0) {
                setFolders(folderOptions.map(x => x.name));
                return;
              }
              setFolders(e.target.value);
            }}
            input={<Input/>}
            inputProps={{
              name: "folder-field",
              "aria-label": "Folder Field",
            }}
            renderValue={(selected) => selected.join(", ")}
            autoWidth={false}
            className={classes.select}
          >
            {folderOptions
              .map(folderOption => (
                <MenuItem
                  key={folderOption.name}
                  value={folderOption.name}
                >
                  <Checkbox checked={folders.indexOf(folderOption.name) > -1} />
                  <ListItemText primary={folderOption.name} />
                </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box p={2} display="flex" alignItems="flex-end">
        <Button
          color="primary"
          variant="outlined"
          disabled={!enableSubmit}
          onClick={() => {
            update({
              board,
              dateWithin,
              directory,
              fileName,
              fileProcess,
              folders: folders.map(x => FolderOptions.find(y => y.name === x).id),
              fromDate,
              toDate
            });
          }}
        >
          <CopyProvider
            page={"files"}
            type={"label"}
            id={"search"}
          />
        </Button>
      </Box>
    </Box>
  );
}

SearchForm.propTypes = {
  update: PropTypes.func.isRequired,
};
