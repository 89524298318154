export default {
  root: {
    fontWeight: 600,
    textTransform: "initial",
  },
  labelIcon: {
    minHeight: "50px",
  },
  wrapper: {
    flexDirection: "row",
    // "& > *:first-child": {
    //   marginBottom: "0px !important",
    //   marginRight: "6px",
    // },
  },
  textColorSecondary: {
    color: "#366b92",
  },
};
