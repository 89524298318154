import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  FormControlLabel,
  Checkbox,
  TextField,
} from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  row: {
    marginBottom: theme.spacing(2),
  },
  required: {
    color: theme.palette.error.light,
  },
  label: {
    ...theme.typography.h6,
    marginBottom: theme.spacing(1),
  },
}));

const PathAndAllowDownload = React.memo(function PathAndAllowDownload(props) {
  const classes = useStyles();
  const {
    dispatch,
    fileDirectory,
    allowDownload,
    allowOtipSender,
    allowEmployerSender,
  } = props;
  const [fileDirectoryError, setFileDirectoryError] = useState("");

  const validateFileDirectory = useCallback(() => {
    if (fileDirectory === "") {
      setFileDirectoryError("This is a required field.");
    } else {
      setFileDirectoryError("");
    }
  }, [fileDirectory]);

  return (
    <>
      <div className={classes.row}>
        <Typography className={classes.label}>File Path</Typography>
        <TextField
          aria-label="File Path"
          error={fileDirectoryError !== ""}
          helperText={fileDirectoryError}
          variant="outlined"
          fullWidth
          size="small"
          value={fileDirectory}
          disabled={allowOtipSender === true && allowEmployerSender === false}
          onChange={(e) => {
            dispatch({
              type: "SET_FILE_PATH",
              payload: e.target.value,
            });
          }}
          onBlur={validateFileDirectory}
        ></TextField>
      </div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={allowDownload}
              onChange={(e) => {
                dispatch({
                  type: "SET_ALLOW_DOWNLOAD",
                  payload: e.target.checked,
                });
              }}
              name="allow-file-download"
              color="primary"
            />
          }
          label="Allow File Download"
        />
      </div>
    </>
  );
});

PathAndAllowDownload.propTypes = {
  dispatch: PropTypes.func.isRequired,
  fileDirectory: PropTypes.string.isRequired,
  allowDownload: PropTypes.bool.isRequired,
  allowOtipSender: PropTypes.bool.isRequired,
  allowEmployerSender: PropTypes.bool.isRequired,
};

export default PathAndAllowDownload;
