const fileProcessReducer = (state, action) => {
  switch (action.type) {
    case "SET_NAME": {
      return {
        ...state,
        name: action.payload,
      };
    }
    case "SET_FRENCH_NAME": {
      return {
        ...state,
        frenchName: action.payload,
      };
    }
    case "SET_DESCRIPTION": {
      return {
        ...state,
        description: action.payload,
      };
    }
    case "SET_FRENCH_DESCRIPTION": {
      return {
        ...state,
        frenchDescription: action.payload,
      };
    }
    case "SET_EFFECTIVE_DATE": {
      return {
        ...state,
        effectiveDate: action.payload,
      };
    }
    case "SET_EXPIRY_DATE": {
      return {
        ...state,
        expiryDate: action.payload,
      };
    }
    case "SET_FREQUENCY": {
      return {
        ...state,
        frequency: action.payload,
      };
    }
    case "SET_ALLOWED_FORMATS": {
      return {
        ...state,
        allowedFormats: action.payload,
      };
    }
    case "SET_ALLOWED_MULTIPLE_FILES": {
      return {
        ...state,
        allowMultipleFiles: action.payload,
      };
    }
    case "SET_ALLOW_OTIP_SENDER": {
      return {
        ...state,
        allowOtipSender: action.payload,
      };
    }
    case "SET_ALLOW_EMPLOYER_SENDER": {
      return {
        ...state,
        allowEmployerSender: action.payload,
      };
    }
    case "SET_NOTIFY_GROUP": {
      return {
        ...state,
        otipNotificationGroup: action.payload,
      };
    }
    case "SET_FILE_PATH": {
      return {
        ...state,
        fileDirectory: action.payload,
      };
    }
    case "SET_ALLOW_DOWNLOAD": {
      return {
        ...state,
        allowDownload: action.payload,
      };
    }
    case "SET_EMPLOYER_LIST": {
      return {
        ...state,
        employerList: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default fileProcessReducer;
