import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import CopyProvider from "../components/CopyProvider/CopyProvider";
import React from "react";

dayjs.extend(utc);

export const convertISOFormat = (isoDate) => {
  const isToday =
    dayjs().format("YYYY-MM-DD") === dayjs(isoDate).format("YYYY-MM-DD");
  let date;
  if (isToday) {
    date = <><CopyProvider page={"files"} type={"label"} id={"today"} ><span></span></CopyProvider>{" "}<span>{dayjs(isoDate).format("h:mm A")}</span> </>;
  } else {
    date = dayjs(isoDate).format("YYYY-MM-DD h:mm A");
  }
  return date;
};

export const dayjsToUTC = (dayjsObject) => {
  return dayjs(dayjsObject).utc().format();
};

export const utcToDayjs = (utc) => {
  return dayjs(utc);
};

export function getDefaultFromDate(today) {
  return today.subtract(30, 'day').startOf('day');
}

export function getDefaultToDate(today) {
  return today.endOf('day');
}
