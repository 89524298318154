import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  footer: {
    // position: "absolute",
    // bottom: 0,
    // right: 0,
    textAlign: "right",
    fontSize: "13px",
    padding: "16px 24px",
  },
}));

function Footer() {
  const classes = useStyle();
  return (
    <Typography variant="body1" className={classes.footer}>
      Copyright © OTIP|RAEO
    </Typography>
  );
}

export default Footer;
