import React from "react";
import Badge from "@material-ui/core/Badge";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 24px 0 8px",
  },
}));

function SelectedFilesCounter(props) {
  const { count } = props;
  const classes = useStyles();
  return <Badge badgeContent={count} className={classes.root} overlap="circular"></Badge>;
}

SelectedFilesCounter.propTypes = {
  count: PropTypes.number.isRequired,
};

export default SelectedFilesCounter;
