import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { setModalClose } from "../../actions/index";
import EmployerSelector from "../../components/EmployerSelector/EmployerSelector";
import CopyProvider from "../CopyProvider/CopyProvider";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 350,
  },
  dialogTitle: {
    textAlign: "center",
    marginBottom: "2rem",
  },
  dialogTitleText: {
    ...theme.typography.h4,
  },
  dialogActions: {
    justifyContent: "center",
    marginTop: "2rem",
  },
}));

function SelectEmployerModal() {
  const dispatch = useDispatch();
  const schoolBoardNumber = useSelector((state) => state.employerInfo.id);
  const classes = useStyles();

  return (
    <Dialog
      disableEnforceFocus
      disableBackdropClick
      disableEscapeKeyDown
      open={true}
      maxWidth="sm"
      fullWidth
      aria-labelledby="alert-dialog-title"
    >
      <div className={classes.dialogTitle}>
        <h3 id="alert-dialog-title" className={classes.dialogTitleText}>
          <CopyProvider
            page={"select employer"}
            type={"label"}
            id={"pleasesel"}
          />
        </h3>
      </div>
      <DialogContent>
        <EmployerSelector id="employer-selector-modal" />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          onClick={() => {
            localStorage.setItem("school_board_number", schoolBoardNumber);
            dispatch(setModalClose());
          }}
          variant="outlined"
          color="primary"
          disabled={schoolBoardNumber === ""}
        >
          <CopyProvider
            page={"change password"}
            type={"label"}
            id={"continue"}
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SelectEmployerModal;
