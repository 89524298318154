export default {
  root: {
    height: "auto",
    "&:focus": {
      textDecoration: "underline"
    }
  },
  button: {
    height: "auto",
    "&:hover": {
      cursor: "pointer"
    }
  }
};
