import { maximumFileSize, maximumFileUpload, maximumFileBulkUpload } from "../configs/config";
import bytesToSize from "../utils/bytesToSize";

const enMessageCodeMapping = (errorCode, messageItems) => {
  switch (errorCode) {
    // ----------- API ERRORS -----------

    case "OE300":
      return "Error while performing Upload. Please contact your Employer Relations Representative.";
    case "OE320":
      return "Error while performing Upload. Please contact your Employer Relations Representative.";
    case "OE321":
      return "Error while performing Upload. Please contact your Employer Relations Representative.";
    case "OE322":
      return "Error while performing Upload. Please contact your Employer Relations Representative.";
    case "OE323":
      return "Error while performing Upload. Please contact your Employer Relations Representative.";
    case "OE325":
      return `File ${messageItems[0].slice(
        messageItems[0].indexOf("[") + 1,
        messageItems[0].indexOf("]")
      )} is a duplicate of one previously received.`;
    case "OE326":
      return "Error while performing Upload. Please contact your Employer Relations Representative.";
    case "OE347":
      return "File path does not exist.";
    case "OE349":
      return "Process name is a duplicate.";
    case "OE353":
      return `File "${messageItems[0]}" could not be uploaded. The file process is not assigned to the employer.`
    case "OE354":
      return `File "${messageItems[0].slice(
        messageItems[0].indexOf("[") + 1,
        messageItems[0].indexOf("]")
      )}" could not be upload. Filename cannot be longer than 239 characters. Please rename the file and upload again.`;

    // ----------- API WARNINGS -----------
    case "OE700":
      return "Generic api warning.";
    case "OE720":
      return "File Upload: generic warning.";
    case "OE721":
      return "File Upload: could not drop file at drop location.";

    // ----------- UI ERRORS -----------
    case "OE1000":
      return `"${messageItems[0]}" - File size cannot exceed ${bytesToSize(
        maximumFileSize
      )}.`;
    case "OE1001":
      return `"${messageItems[0]}" - File is empty.`;
    case "OE1002":
      return `"${messageItems[0]}" - File type is not supported.`;
    case "OE1003":
      return `Number of files exceeds maximum of ${maximumFileUpload} to upload.`;
    case "OE1004":
      return `"${messageItems[0]} - File is a duplicate of one previously received."`;
    case "OE1005":
      return `"${messageItems[0]}" - The filename prefix doesn't contain a valid board number.`;

    // ----------- UI SUCCESS -----------
    case "OE2000":
      return "File upload completed successfully.";
    case "OE2001":
      return `${messageItems[0]} successfully created.`;
    case "OE2002":
      return `${messageItems[0]} successfully updated.`;
    case "OE2003":
      return "File processes updated successfully.";
    case "OE2004":
      return "File deleted successfully.";
    case "OE2005":
      return "File undeleted successfully.";

    // ----------- UI WARNINGS -----------
    case "OE3000":
      return `Number of files exceeds maximum of ${maximumFileUpload} to upload.`;
    case "OE3001":
      return `Number of files exceeds maximum of ${maximumFileBulkUpload} to upload.`;

    // ----------- DEFAULT MESSAGE -----------
    default:
      return `${
        errorCode === undefined ? "OE000 - " : errorCode + " - "
      }Error occurred. Please contact your Employer Relations Representative.`;
  }
};

const frMessageCodeMapping = (errorCode, messageItems) => {
  switch (errorCode) {
    case "OE300":
      return "Erreur lors de l'exécution du téléchargement. Communiquez avec votre représentante ou représentant des relations avec les employeurs si vous éprouvez des difficultés à vous connecter.";
    case "OE320":
      return "Erreur lors de l'exécution du téléchargement. Communiquez avec votre représentante ou représentant des relations avec les employeurs si vous éprouvez des difficultés à vous connecter.";
    case "OE321":
      return "Erreur lors de l'exécution du téléchargement. Communiquez avec votre représentante ou représentant des relations avec les employeurs si vous éprouvez des difficultés à vous connecter.";
    case "OE322":
      return "Erreur lors de l'exécution du téléchargement. Communiquez avec votre représentante ou représentant des relations avec les employeurs si vous éprouvez des difficultés à vous connecter.";
    case "OE323":
      return "Erreur lors de l'exécution du téléchargement. Communiquez avec votre représentante ou représentant des relations avec les employeurs si vous éprouvez des difficultés à vous connecter.";
    case "OE325":
      return `Le fichier ${messageItems[0].slice(
        messageItems[0].indexOf("[") + 1,
        messageItems[0].indexOf("]")
      )} a déjà été reçu précédemment.`;
    case "OE326":
      return "TErreur lors de l'exécution du téléchargement. Communiquez avec votre représentante ou représentant des relations avec les employeurs si vous éprouvez des difficultés à vous connecter.";
    case "OE349":
      return "File Process CRUD: le nom du processus de fichier doit être unique.";
    case "OE353":
      return `File "${messageItems[0]}" could not be uploaded. The file process is not assigned to the employer.`
    case "OE354":
      return `File "${messageItems[0].slice(
        messageItems[0].indexOf("[") + 1,
        messageItems[0].indexOf("]")
      )}" could not be upload. Filename cannot be longer than 239 characters. Please rename the file and upload again. FR`;

    // ----------- API WARNINGS -----------
    case "OE700":
      return "Avertissement générique sur l'API.";
    case "OE720":
      return "Téléchargement de fichier: avertissement générique.";
    case "OE721":
      return "Téléchargement de fichier: impossible de déposer le fichier à l'emplacement de dépôt.";

    // ----------- UI ERRORS -----------
    case "OE1000":
      return `"${
        messageItems[0]
      }" - La taille du fichier ne peut dépasser ${bytesToSize(
        maximumFileSize
      )}.`;
    case "OE1001":
      return `"${messageItems[0]}" - Le fichier est vide.`;
    case "OE1002":
      return `"${messageItems[0]}" - Le type de fichier n’est pas pris en charge.`;
    case "OE1003":
      return `Le nombre de fichiers dépasse le maximum de ${messageItems[0]} fichiers pouvant être téléchargés.`;
    case "OE1004":
      return `"${messageItems[0]}" - Le fichier a déjà été reçu précédemment.`;
    case "OE1005":
      return `"${messageItems[0]}" - The filename prefix doesn't contain a valid board number.`;

    // ----------- UI SUCCESS -----------
    case "OE2000":
      return "Le téléchargement du fichier s'est terminé avec succès.";
    case "OE2001":
      return `${messageItems[0]} créé avec succès.`;
    case "OE2002":
      return `${messageItems[0]} mis à jour avec succès.`;
    case "OE2003":
      return "Processus de fichiers mis à jour avec succès.";
    case "OE2004":
      return "File deleted successfully. FR";
    case "OE2005":
      return "File undeleted successfully. FR";

    // ----------- UI WARNINGS -----------
    case "OE3000":
      return `Le nombre de fichiers dépasse le maximum de ${maximumFileUpload} à télécharger.`;
    case "OE3001":
      return `Le nombre de fichiers dépasse le maximum de ${maximumFileBulkUpload} à télécharger.`;

    // ----------- DEFAULT MESSAGE -----------
    default:
      return `${
        errorCode === undefined ? "OE000 - " : errorCode + " - "
      }Erreur est survenue. Communiquez avec votre représentante ou représentant des relations avec les employeurs si vous éprouvez des difficultés à vous connecter.`;
  }
};

const messageCodeMapping = (errorCode, langPref, messageItems) => {
  if (langPref === "en") return enMessageCodeMapping(errorCode, messageItems);
  else return frMessageCodeMapping(errorCode, messageItems);
};

export default messageCodeMapping;
