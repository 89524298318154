import React from "react";
import { makeStyles } from "@material-ui/styles";
import classnames from "classnames";
import { Link as RouterLink } from "react-router-dom";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  rootPathLink: {
    padding: "0 0.5rem",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  rootPathLinkIcon: {
    fontSize: "22px",
    lineHeight: 1,
    color: theme.palette.primary.main,
  },
  rootPath: {
    padding: "0 0.5rem",
    color: theme.palette.text.primary,
  },
  rootPathIcon: {
    fontSize: "22px",
    lineHeight: 1,
    color: theme.palette.text.primary,
  },
  childPath: {
    padding: "0 0.5rem",
  },
}));

export default function PagePath(props) {
  const { iconName, rootPath, displayRootPath, childPath, displayChildPath } = props;
  const classes = useStyles();
  return (
    <div>
      {childPath ? (
        <>
          <i
            className={classnames(`fal ${iconName}`, {
              [classes.rootPathLinkIcon]: true,
            })}
          />
          <RouterLink to={`/${rootPath.toLowerCase()}`}>
            <Typography
              variant="h3"
              color="primary"
              display="inline"
              className={classes.rootPathLink}
            >
              {displayRootPath}
            </Typography>
          </RouterLink>
        </>
      ) : (
        <>
          <i
            className={classnames(`fal ${iconName}`, {
              [classes.rootPathIcon]: true,
            })}
          />
          <Typography
            variant="h3"
            color="primary"
            display="inline"
            className={classes.rootPath}
          >
            {displayRootPath}
          </Typography>
        </>
      )}
      {childPath ? (
        <>
          <Typography variant="h3" display="inline" color="primary">
            /
          </Typography>
          <Typography
            variant="h3"
            display="inline"
            className={classes.childPath}
          >
            {displayChildPath}
          </Typography>
        </>
      ) : null}
    </div>
  );
}
