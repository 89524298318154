import { SET_UNREAD_FILES_COUNT } from "../actions/index";

const unreadFilesCountReducer = (state = 0, action) => {
  switch (action.type) {
    case SET_UNREAD_FILES_COUNT: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

export default unreadFilesCountReducer;
