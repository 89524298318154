import axios from "axios";
import { webApi } from "../configs/config";
import { getAccessToken } from "./auth";

export const getDataFromAPI = async (endpoint) => {
  try {
    const accessToken = await getAccessToken();
    const res = await axios.get(`${webApi}${endpoint}`, {
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Authorization: "Bearer " + accessToken,
      },
    });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const postDataToAPI = async (
  endpoint,
  data,
  contentType = "application/json"
) => {
  try {
    const accessToken = await getAccessToken();
    const res = await axios.post(`${webApi}${endpoint}`, data, {
      headers: {
        "Content-Type": contentType,
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Authorization: "Bearer " + accessToken,
      },
    });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const updateDataToAPI = async (
  endpoint,
  data,
  contentType = "application/json"
) => {
  try {
    const accessToken = await getAccessToken();
    const res = await axios.put(`${webApi}${endpoint}`, data, {
      headers: {
        "Content-Type": contentType,
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Authorization: "Bearer " + accessToken,
      },
    });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const deleteDataFromAPI = async (
  endpoint,
  data,
  contentType = "application/json"
) => {
  try {
    const accessToken = await getAccessToken();
    const res = await axios.delete(`${webApi}${endpoint}`, {
      headers: {
        "Content-Type": contentType,
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Authorization: "Bearer " + accessToken,
      },
      data,
    });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const patchDataToAPI = async (
  endpoint,
  data,
  contentType = "application/json-patch+json"
) => {
  try {
    const accessToken = await getAccessToken();
    const res = await axios.patch(`${webApi}${endpoint}`, data, {
      headers: {
        "Content-Type": contentType,
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Authorization: "Bearer " + accessToken,
      },
    });
    return res.data;
  } catch (err) {
    return err;
  }
};
