import React, { useState, useCallback } from "react";
import { TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  row: {
    marginBottom: theme.spacing(2),
  },
  required: {
    color: theme.palette.error.light,
  },
  label: {
    ...theme.typography.h6,
    marginBottom: theme.spacing(1),
  },
}));

const ProcessName = React.memo(function ProcessName(props) {
  const classes = useStyles();
  const { dispatch, name, frenchName } = props;
  const [nameError, setNameError] = useState("");
  const [frenchNameError, setFrenchNameError] = useState("");

  const validateName = useCallback(() => {
    if (name === "") {
      setNameError("This is a required field.");
    } else {
      setNameError("");
    }
  }, [name]);

  const validateFrenchName = useCallback(() => {
    if (frenchName === "") {
      setFrenchNameError("This is a required field.");
    } else {
      setFrenchNameError("");
    }
  }, [frenchName]);

  return (
    <div>
      <div className={classes.row}>
        <Typography className={classes.label}>
          Process Name (English) <span className={classes.required}>*</span>
        </Typography>
        <TextField
          id="process-name-en"
          error={nameError !== ""}
          helperText={nameError}
          variant="outlined"
          fullWidth
          size="small"
          value={name}
          onChange={(e) => {
            dispatch({
              type: "SET_NAME",
              payload: e.target.value,
            });
          }}
          onBlur={validateName}
        ></TextField>
      </div>
      <div className={classes.row}>
        <Typography className={classes.label}>
          Process Name (French) <span className={classes.required}>*</span>
        </Typography>
        <TextField
          aria-label="Process Name English"
          error={frenchNameError !== ""}
          helperText={frenchNameError}
          variant="outlined"
          fullWidth
          size="small"
          value={frenchName}
          onChange={(e) => {
            dispatch({
              type: "SET_FRENCH_NAME",
              payload: e.target.value,
            });
          }}
          onBlur={validateFrenchName}
        ></TextField>
      </div>
    </div>
  );
});

ProcessName.propTypes = {
  dispatch: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  frenchName: PropTypes.string.isRequired,
};

export default ProcessName;
