import messageCodeMapping from "../utils/messageCodeMapping";

export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
export const SET_WARNING_MESSAGE = "SET_WARNING_MESSAGE";
export const SET_SUCCESS_MESSAGE = "SET_SUCCESS_MESSAGE";
export const SET_CLEAR_MESSAGE = "SET_CLEAR_MESSAGE";

export const setErrorMessage = (errorCode, messageItems = null) => {
  return (dispatch, getState) => {
    let languagePreference;
    const { group } = getState().userInfo;
    const { id, langPref } = getState().employerInfo;
    if (group.toLowerCase() === "otip") {
      languagePreference = "en";
    } else {
      languagePreference = langPref;
    }
    if (id === "") {
      dispatch({
        type: SET_ERROR_MESSAGE,
        payload: {
          severity: "error",
          message:
            "OE000 - Erreur est survenue. Communiquez avec votre représentante ou représentant des relations avec les employeurs si vous éprouvez des difficultés à vous connecter.",
        },
      });
      dispatch({
        type: SET_ERROR_MESSAGE,
        payload: {
          severity: "error",
          message:
            "OE000 - Errors occurred. Please contact your Employer Relations Representative.",
        },
      });
      return;
    }
    const message = messageCodeMapping(
      errorCode,
      languagePreference,
      messageItems
    );
    if (errorCode && errorCode.includes("OE7")) {
      dispatch({
        type: SET_WARNING_MESSAGE,
        payload: { severity: "warning", message },
      });
    } else {
      dispatch({
        type: SET_ERROR_MESSAGE,
        payload: { severity: "error", message },
      });
    }
  };
};

export const setWarningMessage = (messageCode, messageItems = null) => {
  return (dispatch, getState) => {
    let languagePreference;
    const { group } = getState().userInfo;
    const { langPref } = getState().employerInfo;
    if (group.toLowerCase() === "otip") {
      languagePreference = "en";
    } else {
      languagePreference = langPref;
    }
    const warningMessage = messageCodeMapping(
      messageCode,
      languagePreference,
      messageItems
    );
    dispatch({
      type: SET_WARNING_MESSAGE,
      payload: { severity: "warning", message: warningMessage },
    });
  };
};

export const setSuccessMessage = (messageCode, messageItems = null) => {
  return (dispatch, getState) => {
    let languagePreference;
    const { group } = getState().userInfo;
    const { langPref } = getState().employerInfo;
    if (group.toLowerCase() === "otip") {
      languagePreference = "en";
    } else {
      languagePreference = langPref;
    }
    const successMessage = messageCodeMapping(
      messageCode,
      languagePreference,
      messageItems
    );
    dispatch({
      type: SET_SUCCESS_MESSAGE,
      payload: { severity: "success", message: successMessage },
    });
  };
};

export const setClearMessage = () => ({
  type: SET_CLEAR_MESSAGE,
});
