import React from "react";
import PropTypes from "prop-types";
import { Checkbox, TableCell, TableHead, TableRow, TableSortLabel } from "@material-ui/core";
import { useSelector } from "react-redux";
import { implies } from "../../utils/logic";

export const isRestrictedHeaderKey = "isRestricted";
export const processNameHeaderKey = "processName";
export const fileNameHeaderKey = "filename";
export const boardHeaderKey = "board";
export const folderHeaderKey = "folder";
export const uploadedByHeaderKey = "uploadedBy";
export const uploadedHeaderKey = "uploaded";
export const actionsHeaderKey = "actions";

const headCells = [
  { id: isRestrictedHeaderKey, disablePadding: true, label: "", sort: false },
  { id: processNameHeaderKey, disablePadding: false, label: "Process Name", sort: true },
  { id: fileNameHeaderKey, disablePadding: false, label: "Filename", sort: true },
  { id: boardHeaderKey, disablePadding: false, label: "Board", sort: false },
  // { id: "directory", disablePadding: false, label: "Directory", sort: true },
  { id: folderHeaderKey, disablePadding: false, label: "Folder", sort: true },
  { id: uploadedByHeaderKey, disablePadding: false, label: "Uploaded By", sort: true },
  { id: uploadedHeaderKey, disablePadding: false, label: "Upload date", sort: true },
  { id: actionsHeaderKey, align: "center", disablePadding: true, label: "Actions", sort: false },
];

/**
 * @param {Object} props
 * @param {Object} props.classes CSS classes
 * @param {Boolean} props.disableSelectAll Disable Select All button
 * @param {Function} props.onSelectAllClick The delegate callback to handle Select All
 * @param {String} props.order The order i.e. asc/desc
 * @param {any} props.orderBy The column ID to order by
 * @param {Number} props.numSelected The number of rows selected
 * @param {Number} props.rowCount The number of rows
 * @param {Function} props.onRequestSort The delegate callback to sort the search results
 * @param {Boolean} props.toggleBoardColumn Display the Board column
 * @returns The Search Table Head React component 
 */
export default function SearchTableHead({ classes, disableSelectAll, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, toggleBoardColumn }) {
  const employerFolderOptionBlacklist = [actionsHeaderKey];
  const userGroup = useSelector((state) => state.userInfo.group);
  const isOtip = userGroup && userGroup.toLowerCase() === "otip";
  const visibleHeaders = headCells
    .filter(x => {
      if (isOtip) {
        return true;
      } else {
        return !employerFolderOptionBlacklist.includes(x.id);
      }
    })
    .filter(x => {
      return implies(x.id === boardHeaderKey, toggleBoardColumn);
    });
  
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
            disabled={disableSelectAll}
          />
        </TableCell>
        {visibleHeaders.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align ? headCell.align : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={headCell.sort && orderBy === headCell.id}
              direction={headCell.sort && orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              hideSortIcon={!Boolean(headCell.sort)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

SearchTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  disableSelectAll: PropTypes.bool.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  toggleBoardColumn: PropTypes.bool.isRequired,
};