import React, { useCallback } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  Link,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { setModalClose } from "../../actions/index";
import { webApi } from "../../configs/config";
import { postDataToAPI } from "../../utils/api";
import PropTypes from "prop-types";
import { convertISOFormat } from "../../utils/time";
import CopyProvider from "../CopyProvider/CopyProvider";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 350,
  },
  dialogTitle: {
    textAlign: "center",
    marginBottom: "2rem",
  },
  dialogTitleText: {
    ...theme.typography.h4,
  },
  dialogActions: {
    justifyContent: "flex-end",
    marginTop: "2rem",
  },
  actionButton: {
    margin: "0 1rem",
  },
  list: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#ffffff",
  },
  fileName: {
    ...theme.typography.body1,
    letterSpacing: "0.1px",
    maxWidth: "500px",
    overflowWrap: "break-word",
  },
  timestamp: {
    ...theme.typography.body1,
    letterSpacing: "0.1px",
    maxWidth: "150px",
    overflowWrap: "break-word",
  },
}));

/**
 * 
 * @param {Object} props
 * @param {{id, fileName, uploaded}[]} props.selectedFileList
 * @param {Function} props.fetchHandler
 * @param {Boolean | undefined} props.markAsOpened
 * @returns 
 */

function DownloadFilesModal({ selectedFileList, fetchHandler, markAsOpened }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  //add hidden anchor with "download" attribute to prevent browser inline preview
  const handleDownload = useCallback(() => {
    const downloadFile = (file, i) =>
      new Promise((resolve, _) => {
        setTimeout(() => {
          postDataToAPI(`/files/download/${file.id}`, {}).then((token) => {
            const a = document.createElement("a");
            a.style.display = "none"; //hidden
            document.body.appendChild(a); //add to DOM
            a.href = `${webApi}/files/download?token=${token}&markAsOpened=${Boolean(markAsOpened)}`;
            a.download = file.fileName;
            a.click(); //trigger download
            document.body.removeChild(a); //remove from DOM

            resolve();
          });
        }, i * 500);
      });

    Promise.all(selectedFileList.map(downloadFile)).then(() =>
      setTimeout(fetchHandler, selectedFileList.length * 500 + 2000)
    );
    dispatch(setModalClose());
  }, [selectedFileList, dispatch, fetchHandler, markAsOpened]);

  //cancel the downloads
  const handleCancel = useCallback(() => {
    dispatch(setModalClose());
  }, [dispatch]);

  return (
    <Dialog
      disableEnforceFocus
      disableBackdropClick
      disableEscapeKeyDown
      open={true}
      maxWidth="md"
      fullWidth
      aria-labelledby="alert-dialog-title"
    >
      <div className={classes.dialogTitle}>
        <h3 id="alert-dialog-title" className={classes.dialogTitleText}>
          <CopyProvider
            page={"download file"}
            type={"label"}
            id={"youareab"}
            inserts={[selectedFileList.length]}
          />
        </h3>
      </div>
      <DialogContent>
        <List>
          {selectedFileList.map((file) => (
            <ListItem className={classes.list} key={file.id} divider>
              <div className={classes.fileName}>{file.fileName}</div>
              <div className={classes.timestamp}>
                {convertISOFormat(file.uploaded)}
              </div>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Link
          color="primary"
          variant="h6"
          component="button"
          className={classes.actionButton}
          onClick={handleCancel}
        >
          <CopyProvider page={"change password"} type={"label"} id={"cancel"} />
        </Link>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleDownload}
          className={classes.actionButton}
          disabled={selectedFileList.length <= 0}
        >
          <CopyProvider page={"download file"} type={"label"} id={"download"} />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DownloadFilesModal.propTypes = {
  selectedFileList: PropTypes.array.isRequired,
  fetchHandler: PropTypes.func.isRequired,
  markAsOpened: PropTypes.bool,
};

export default DownloadFilesModal;
