import { getDataFromAPI } from "../utils/api";
import { setErrorMessage } from "./index";

export const SET_UNREAD_FILES_COUNT = "SET_UNREAD_FILES_COUNT";

export const setUnreadFilesCount = (unreadFilesCount) => {
  return {
    type: SET_UNREAD_FILES_COUNT,
    payload: unreadFilesCount,
  };
};

export const fetchUnreadFilesCount = (employerId) => {
  return (dispatch) => {
    const getUnreadFilesCount = async () => {
      const result = await getDataFromAPI(
        `/files/countUnreadFiles?employerId=${employerId}`
      );
      if (result instanceof Error) {
        if (
          result.response &&
          result.response.data &&
          result.response.data !== ""
        ) {
          dispatch(setErrorMessage(result.response.data.code));
        } else {
          dispatch(setErrorMessage("OE000"));
        }
      } else {
        if (result >= 0 && result !== null) {
          dispatch(setUnreadFilesCount(result));
        }
      }
    };
    getUnreadFilesCount();
  };
};
