import { useEffect } from "react";
import { postDataToAPI } from "../../utils/api";

const DOMEvents = ["click", "keydown"];

const lastUserInteractionKey = "lastUserInteraction";

/**
 * Two responsibilities:
 * 1. Log "lastUserInteraction" date for every user interactions in localStorage
 * 2. Heartbeat thread pings server if in heartbeatThreshold
 * @param {*} props
 * @returns the Heartbeat Check React component
 */
function HeartbeatCheck({ heartbeatThreadTimeout, heartbeatThresholdMS }) {

    const heartbeatThreadTimeoutOrDefault = heartbeatThreadTimeout ? heartbeatThreadTimeout : 60 * 1000;
    const heartbeatThresholdMSOrDefault = heartbeatThresholdMS ? heartbeatThresholdMS : 15 * 60 * 1000;

    const recordLastUserInteraction = () => {
        const now = new Date();
        localStorage.setItem(lastUserInteractionKey, now);
        return now;
    };

    const addEvents = () => {
        DOMEvents.forEach(event => {
            window.addEventListener(event, recordLastUserInteraction);
        })
    }
    
    const removeEvents = () => {
        DOMEvents.forEach(event => {
            window.removeEventListener(event, recordLastUserInteraction);
        })
    };

    const heartbeatThread = () => {
        let storedLastInteractionTime = localStorage.getItem(lastUserInteractionKey);
        if (!storedLastInteractionTime) {
            storedLastInteractionTime = recordLastUserInteraction();
        }
        const lastInteractionTime = new Date(Date.parse(storedLastInteractionTime));
        const diff = new Date() - lastInteractionTime;
        if (diff > heartbeatThresholdMSOrDefault)
        {
            return;
        }

        postDataToAPI("/Heartbeats")
            .catch(error => console.error("Heartbeat failure:", error));
    };

    useEffect(() => {
        addEvents();
        const timeoutId = setInterval(heartbeatThread, heartbeatThreadTimeoutOrDefault);
        return (() => {
            removeEvents();
            clearTimeout(timeoutId);
        })
    });

    return null;
}

export default HeartbeatCheck;
