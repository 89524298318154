export default {
  tooltip: {
    backgroundColor: "#366b92",
    fontSize: "14px",
    padding: "5px 8px"
  },
  arrow: {
    color: "#366b92"
  }
};
