import React, { forwardRef, useImperativeHandle, useMemo } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Checkbox, Link, Paper, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Tooltip } from "@material-ui/core";
import classnames from "classnames";
import { SmallTooltip } from "../Files/FileItem";
import { FolderOptions } from "./SearchForm";
import SearchTableToolbar from "./SearchTableToolarbar";
import SearchTableHead, { uploadedHeaderKey } from "./SearchTableHeader";
import { useDispatch, useSelector } from "react-redux";
import { setModalOpen } from "../../actions/modalActions";
import { convertISOFormat } from "../../utils/time";
import _ from "lodash";
import CopyProvider from "../../components/CopyProvider/CopyProvider";
import { setErrorMessage, setSuccessMessage } from "../../actions/messageActions";
import { patchDataToAPI } from "../../utils/api";
import Permissions, { hasPermission } from "../../components/Permissions/Permissions";

const asc = "asc";
const desc = "desc";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === desc
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  /* from FileItem.js */
  iconFilled: {
    fontWeight: 900,
  },
  fileIcon: {
    fontSize: "20px",
    color: theme.palette.primary.main,
    margin: "0 18px 0 20px",
  },
  fileIconRed: {
    color: theme.palette.error.main,
  },
  fileProcessName: {
    ...theme.typography.body1,
    letterSpacing: "0.1px",
  },
  fileName: {
    ...theme.typography.subtitle2,
    fontSize: 13,
    letterSpacing: "0.3px",
  },
  bold: {
    fontWeight: "600 !important",
  },
  overflowText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textWrap: "nowrap",
    maxWidth: 250,
  },
  marginFreeIcon: {
    margin: 0
  },
}));

/**
 * 
 * @param {{isRestricted}} row Search table row
 * @param {boolean} permissions The user permissions array
 */
export function isDownloadable(row, permissions) {
  // return !row.isRestricted;
  return !row.isRestricted || hasPermission(permissions, ["read"], "filedownloadrestricted");
}

/**
 * @param {Object} props
 * @param {Function} props.fetchHandler Update the search results
 * @param {Array} props.rows The list of search results
 * @param {Boolean} props.toggleBoardColumn Display the Board column
 * @param {Function} props.toggleBoardColumnChanged Toggle board column delegate callback
 * @returns The Search Results React Hook
 */
const SearchTableResults = forwardRef(({ fetchHandler, rows, toggleBoardColumn, toggleBoardColumnChanged }, ref) => {
  const classes = useStyles();
  const [order, setOrder] = React.useState(desc);
  const [orderBy, setOrderBy] = React.useState(uploadedHeaderKey);
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const dispatch = useDispatch();

  const employerList = useSelector((state) => state.employerList, _.isEqual);

  const userGroup = useSelector((state) => state.userInfo.group);

  const permissions = useSelector((state) => state.permissions);

  useImperativeHandle(ref, () => ({
    deselectAll() {
      setSelected([]);
    },
  }))

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === asc;
    setOrder(isAsc ? desc : asc);
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked && selected.length === 0) {
      const newSelecteds = downloadableRows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    } else if (event.target.checked && selected.length > 0) {
      setSelected([]);
    } else {
      setSelected([]);
    }
  };

  const handleClick = (event, row) => {
    const id = row.id;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1 && isDownloadable(row, permissions)) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const reduxDispatch = useDispatch();

  const downloadableRows = useMemo(() => rows.filter(x => isDownloadable(x, permissions)), [rows]);

  const isOtip = userGroup && userGroup.toLowerCase() === "otip";

  const isBold = (row) => !row.isOpened;

  function download() {
    reduxDispatch(
      setModalOpen("DownloadFilesModal", {
        selectedFileList: rows.filter(x => isSelected(x.id)),
        fetchHandler,
        markAsOpened: true,
      })
    );
    setSelected([]);
  }

  async function deleteFile(event, row) {
    event.preventDefault();
    const result = await patchDataToAPI(`/files/${row.id}`, [
      {
        "op": "replace",
        "path": "/status",
        "value": "deleted"
      }
    ]);
    if (result instanceof Error) {
      if (result.response && result.response.data) {
        dispatch(setErrorMessage(result.response.data.code));
      } else {
        dispatch(setErrorMessage("OE000"));
      }
    }
    else if (result)
    {
      reduxDispatch(setSuccessMessage("OE2004"));
      fetchHandler();
    }
  }

  async function undoDeleteFile(event, row) {
    event.preventDefault();
    const result = await patchDataToAPI(`/files/${row.id}`, [
      {
        "op": "replace",
        "path": "/status",
        "value": "active"
      }
    ]);
    if (result instanceof Error) {
      if (result.response && result.response.data) {
        dispatch(setErrorMessage(result.response.data.code));
      } else {
        dispatch(setErrorMessage("OE000"));
      }
    }
    else if (result)
    {
      reduxDispatch(setSuccessMessage("OE2005"));
      fetchHandler();
    }
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <SearchTableToolbar download={download} numSelected={selected.length} toggleBoardColumn={toggleBoardColumn} toggleBoardColumnChanged={toggleBoardColumnChanged} />
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader
            className={classes.table}
            aria-labelledby="searchResultsTable"
            size={"small"}
            aria-label="Search Results Table"
          >
            <SearchTableHead
              classes={classes}
              disableSelectAll={downloadableRows.length === 0 || downloadableRows.length > 10}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              toggleBoardColumn={toggleBoardColumn}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const isItemDisabled = !isDownloadable(row, permissions);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const isActive = row.status === "active";
                  const isDeleted = row.status === "deleted";

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                          disabled={isItemDisabled}
                          color="primary"
                        />
                      </TableCell>
                      <TableCell padding="checkbox" align="left">
                        <i
                          className={classnames("fal", {
                            "fa-file-alt": !row.isRestricted,
                            "fa-shield-alt": row.isRestricted,
                            [classes.fileIcon]: true,
                            [classes.fileIconRed]: row.isRestricted,
                            [classes.iconFilled]: !row.isOpened && !row.isRestricted,
                            [classes.marginFreeIcon]: true,
                          })}
                        ></i>
                      </TableCell>
                      <TableCell align="left">
                        <SmallTooltip
                          title={row.processName}
                          placement="right"
                        ><div className={classnames({
                          [classes.fileProcessName]: true,
                          [classes.overflowText]: true,
                          [classes.bold]: isBold(row),
                        })}>{row.processName}</div></SmallTooltip>
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 200, maxWidth: 400, }}>
                        <SmallTooltip
                          title={row.fileName}
                          placement="right"
                        ><div className={classnames({
                          [classes.fileName]: true,
                          [classes.overflowText]: true,
                          [classes.bold]: isBold(row),
                        })}>{row.fileName}</div></SmallTooltip>
                      </TableCell>
                      {toggleBoardColumn && <TableCell align="left" className={classnames({
                        [classes.bold]: isBold(row),
                        })}>
                        <SmallTooltip
                          title={`${row.boardNumber} -- ${employerList.find(x => x.id === row.boardNumber)?.name}`}
                          placement="right"
                        ><div className={classnames({
                          [classes.overflowText]: true,
                        })}>{employerList.find(x => x.id === row.boardNumber)?.name}</div></SmallTooltip>
                      </TableCell>}
                      <TableCell align="left"
                        className={classnames({
                          [classes.date]: true,
                          [classes.overflowText]: true,
                          [classes.bold]: isBold(row),
                        })}>
                        {FolderOptions.find(x => x.id === row.folder)?.name}
                      </TableCell>
                      <TableCell align="left"
                        className={classnames({
                          [classes.date]: true,
                          [classes.overflowText]: true,
                          [classes.bold]: isBold(row),
                        })}>
                        {row.uploadedByName}
                      </TableCell>
                      <TableCell align="left"
                        className={classnames({
                          [classes.date]: true,
                          [classes.overflowText]: true,
                          [classes.bold]: isBold(row),
                        })}>
                        {convertISOFormat(row.uploaded)}
                      </TableCell>
                      <TableCell align="center">
                        {isActive && <Permissions type={"Files"} actions={["delete"]}>
                          <Tooltip
                            title={
                              <CopyProvider
                                page={"files"}
                                type={"label"}
                                id={"delete"}
                              />
                            }
                            placement="top"
                            arrow
                            className={classes.tooltip}
                          >
                            <Link
                              color="primary"
                              variant="inherit"
                              className={classes.deleteFileLink}
                              onClick={e => deleteFile(e, row)}>
                              <i className={classnames(`fas fa-trash ${classes.fileIcon}`)}></i>
                            </Link>
                          </Tooltip>
                        </Permissions>}
                        {isDeleted && <Permissions type={"Files"} actions={["delete"]}>
                          <Tooltip
                            title={
                              <CopyProvider
                                page={"files"}
                                type={"label"}
                                id={"undodelete"}
                              />
                            }
                            placement="top"
                            arrow
                            className={classes.tooltip}
                          >
                            <Link
                              color="primary"
                              variant="inherit"
                              className={classes.undoDeleteFileLink}
                              arial-label={"test"}
                              onClick={e => undoDeleteFile(e, row)}>
                                <i className={classnames(`fas fa-undo ${classes.fileIcon}`)}></i>
                            </Link>
                          </Tooltip>
                        </Permissions>}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
});

SearchTableResults.propTypes = {
  fetchHandler: PropTypes.func.isRequired,
  rows: PropTypes.array.isRequired,
  toggleBoardColumn: PropTypes.bool.isRequired,
};

export default SearchTableResults;
