import React, { } from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core/index";
import PropTypes from "prop-types";

function FormatCheckboxes({ allowedFormats, handleFormatChange }) {
  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={allowedFormats.includes("xls")}
            onChange={(e) => {
              handleFormatChange(e.target.checked, "xls");
            }}
            name="xls"
            color="primary"
          />
        }
        label="XLS"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={allowedFormats.includes("txt")}
            onChange={(e) => {
              handleFormatChange(e.target.checked, "txt");
            }}
            name="txt"
            color="primary"
          />
        }
        label="TXT"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={allowedFormats.includes("csv")}
            onChange={(e) => {
              handleFormatChange(e.target.checked, "csv");
            }}
            name="csv"
            color="primary"
          />
        }
        label="CSV"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={allowedFormats.includes("doc")}
            onChange={(e) => {
              handleFormatChange(e.target.checked, "doc");
            }}
            name="doc"
            color="primary"
          />
        }
        label="DOC"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={allowedFormats.includes("pdf")}
            onChange={(e) => {
              handleFormatChange(e.target.checked, "pdf");
            }}
            name="pdf"
            color="primary"
          />
        }
        label="PDF"
      />
    </div>
  );
}

FormatCheckboxes.propTypes = {
  allowedFormats: PropTypes.array.isRequired,
  handleFormatChange: PropTypes.func.isRequired,
};

export default FormatCheckboxes;
