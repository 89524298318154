import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },
  rowItem: {
    width: "45%",
  },
  label: {
    ...theme.typography.h6,
    marginBottom: theme.spacing(1),
  },
  required: {
    color: theme.palette.error.light,
  },
  select: {
    width: "100%",
  },
}));

const SenderAndNotifyGroup = React.memo(function SenderAndNotifyGroup(props) {
  const classes = useStyles();
  const {
    dispatch,
    allowOtipSender,
    allowEmployerSender,
    otipNotificationGroup,
    notificationGroups,
  } = props;
  return (
    <>
      <div className={classes.row}>
        <div className={classes.rowItem}>
          <Typography className={classes.label} style={{ marginBottom: 0 }}>
            Sender <span className={classes.required}> *</span>
          </Typography>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={allowOtipSender}
                  onChange={(e) => {
                    dispatch({
                      type: "SET_ALLOW_OTIP_SENDER",
                      payload: e.target.checked,
                    });
                  }}
                  name="otip-as-sender"
                  color="primary"
                />
              }
              label="OTIP"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={allowEmployerSender}
                  onChange={(e) => {
                    dispatch({
                      type: "SET_ALLOW_EMPLOYER_SENDER",
                      payload: e.target.checked,
                    });
                  }}
                  name="employer-as-sender"
                  color="primary"
                />
              }
              label="Employer"
            />
          </FormGroup>
        </div>
        <div className={classes.rowItem}>
          <Typography className={classes.label}>Notify Group</Typography>
          <FormControl
            variant="outlined"
            className={classes.select}
            disabled={allowOtipSender === true && allowEmployerSender === false}
          >
            <Select
              value={otipNotificationGroup}
              onChange={(e) => {
                dispatch({
                  type: "SET_NOTIFY_GROUP",
                  payload: e.target.value,
                });
              }}
              inputProps={{
                name: "notify-group",
                "aria-label": "notify-group",
              }}
            >
              <MenuItem value=""></MenuItem>
              {notificationGroups.map((notificationGroup) => (
                <MenuItem
                  value={notificationGroup.name}
                  key={notificationGroup.id}
                >
                  {notificationGroup.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
    </>
  );
});

SenderAndNotifyGroup.propTypes = {
  dispatch: PropTypes.func.isRequired,
  allowOtipSender: PropTypes.bool.isRequired,
  allowEmployerSender: PropTypes.bool.isRequired,
  otipNotificationGroup: PropTypes.string,
  notificationGroups: PropTypes.array.isRequired,
};

export default SenderAndNotifyGroup;
