import { SET_EMPLOYER_LIST } from "../actions/index";

const employerListReducer = (state = [], action) => {
  switch (action.type) {
    case SET_EMPLOYER_LIST: {
      return [...action.payload];
    }
    default: {
      return state;
    }
  }
};

export default employerListReducer;
