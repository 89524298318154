import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { AppBar, Toolbar, Typography, Link } from "@material-ui/core";
import { useSelector } from "react-redux";
import { login, logout } from "../../utils/auth";
import EmployerSelector from "../../components/EmployerSelector/EmployerSelector";
import EmployerLabel from "./EmployerLabel";
import CopyProvider from "../../components/CopyProvider/CopyProvider";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
    flexGrow: 1,
    paddingLeft: theme.spacing(2),
  },
  flexGrow: {
    flexGrow: 1,
  },
  logButton: {
    marginLeft: theme.spacing(4),
    marginRight: 0,
  },
}));

/**
 * 
 * @param {Object} params
 * @param {?*} params.className The AppBar class name
 * @param {Boolean} params.hideEmployerBoardSelector Hide Employer Board Selector React component
 * @returns the Topbar React hook
 */
function Topbar({ className, hideEmployerBoardSelector, ...rest }) {
  const classes = useStyles();
  const authenticated = useSelector((state) => state.authenticated);
  const userName = useSelector((state) => state.userInfo.name);
  const userGroup = useSelector((state) => state.userInfo.group);

  return (
    <AppBar
      {...rest}
      className={classnames(classes.root, className)}
      color="default"
      position="absolute"
    >
      <Toolbar>
        {!hideEmployerBoardSelector &&
          (userGroup && userGroup.toLowerCase() === "otip" ? (
            <EmployerSelector id="employer-selector-top-nav" />
          ) : (
            <EmployerLabel />
          ))
        }
        <div className={classes.flexGrow} />
        <Typography variant="body1">{userName && userName}</Typography>
        <Link
          color="primary"
          variant="h6"
          component="button"
          className={classes.logButton}
          onClick={authenticated ? logout : login}
        >
        {" "}
        {authenticated && <CopyProvider page={"navigation (top)"} type={"label"} id={"logout"} /> }
        </Link>
      </Toolbar>
    </AppBar>
  );
}

Topbar.propTypes = {
  className: PropTypes.string,
  hideEmployerBoardSelector: PropTypes.bool,
};

export default Topbar;
